import { Button } from '@zendeskgarden/react-buttons';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useBackend } from '../../api/BackendApiService';
import { zafClient } from '../../api/ZendeskApiService';
import { GlobalAlert } from '@zendeskgarden/react-notifications';
import moment from 'moment';
import { Spinner } from '@zendeskgarden/react-loaders';
import Notification from '../../components/notification';

const { currentUser } = await zafClient.get('currentUser');

function InvoiceCheckoutPage({ invoicePay }) {
  const [url, setUrl] = useState(null);
  const [iFrameLoaded, setIFrameLoaded] = useState(false);
  const [iframeKey, setIframeKey] = useState(0);
  const { authData, payByInvoiceApi, invoiceCancellationApi, setNotification, notification, billingDetails } =
    useBackend();
  const intervalRef = useRef(null);

  useEffect(() => {
    zafClient.invoke('resize', { width: '80vw', height: '80vh' });
    const pdfUrl = invoicePay ? invoicePay?.invoicePdf : authData?.subscription?.invoicePdf;
    let invoiceUrl = `https://docs.google.com/viewer?url=${pdfUrl}&embedded=true`;
    setUrl(invoiceUrl);
    if (!iFrameLoaded) {
      intervalRef.current = setInterval(() => {
        setIframeKey(iframeKey + 1);
      }, 5000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [iframeKey, iFrameLoaded]);

  const handleIframeLoad = () => {
    setIFrameLoaded(true);
    clearInterval(intervalRef.current);
  };

  const onClickAllCancelButton = (type) => {
    let button = null;
    let title = null;
    let description = null;
    if (type === 'cancelInvoice') {
      title = 'Cancel Subscription';
      description = `Are you sure you want to cancel your subscription. Please note that your plan will be deactivated on 
        ${moment(authData?.subscription?.expireAt).format('DD-MM-YYYY')}`;
      button = {
        text: 'Yes',
        onClick: () => invoiceCancellationApi('cancel-invoice', authData?.subscription?.expireAt),
      };
    } else {
      title = 'Retract Cancellation';
      description = 'Are you sure, you want to retract cancellation?';
      button = {
        text: 'Yes',
        onClick: () => invoiceCancellationApi('cancel-invoice-cancellation', authData?.subscription?.expireAt),
      };
    }
    const notificationObject = {
      title: title,
      description: description,
      button: button,
      secondButton: { text: 'No', onClick: () => setNotification(null) },
      mode: 'warning',
      hideClose: true,
    };
    setNotification(notificationObject);
  };

  if (notification)
    return (
      <CenterPageContainer>
        <Notification />
      </CenterPageContainer>
    );

  return (
    <div>
      {authData?.subscription?.cancelOn && (
        <GlobalAlert type="warning" style={{ marginRight: '13px' }}>
          <GlobalAlert.Content>
            <GlobalAlert.Title>Subscription Cancelled</GlobalAlert.Title>
            You have cancelled your subscription. You can access your account till{' '}
            {moment(authData?.subscription?.cancelOn).format('DD-MM-YYYY')}
          </GlobalAlert.Content>
          {billingDetails?.customerEmail === currentUser?.email && (
            <GlobalAlert.Button
              style={{ backgroundColor: 'rgb(31, 115, 183)' }}
              onClick={() => onClickAllCancelButton('cancelCancellation')}
            >
              Cancel cancellation
            </GlobalAlert.Button>
          )}
        </GlobalAlert>
      )}
      <ButtonsContainer>
        {authData?.subscription?.status !== 'paid' && (
          <StyledButton onClick={() => payByInvoiceApi(null, 'resend-invoice')}>Send Email</StyledButton>
        )}
        <a href={invoicePay ? invoicePay?.invoicePdf : authData?.subscription?.invoicePdf}>
          <StyledButton isPrimary>Download Invoice</StyledButton>
        </a>
        {authData?.subscription?.status === 'paid' &&
          !authData?.subscription?.cancelOn &&
          billingDetails?.customerEmail === currentUser?.email && (
            <StyledButton onClick={() => onClickAllCancelButton('cancelInvoice')}>Cancel Subscription</StyledButton>
          )}
      </ButtonsContainer>
      <div style={{ height: '100vh' }}>
        {!iFrameLoaded && (
          <CenterPageContainer>
            <LoadingSpinner size="60" color="#1f73b7" />
          </CenterPageContainer>
        )}
        <iframe
          title={iframeKey}
          key={iframeKey}
          src={url}
          frameBorder="0"
          height="100%"
          width="100%"
          onLoad={() => handleIframeLoad()}
        ></iframe>
      </div>
    </div>
  );
}

const CenterPageContainer = styled.div`
  display: grid;
  place-items: center;
  flex: 1;
`;

const LoadingSpinner = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  font-weight: 700;
  font-size: 12px;
  height: 36px;
  float: right;
  margin-right: 15px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin: 30px 0px;
`;

export default InvoiceCheckoutPage;
