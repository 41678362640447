import { Autocomplete, Dropdown, Field, Item, Label, Menu } from '@zendeskgarden/react-dropdowns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useBackend } from '../../../api/BackendApiService';
import { InfoTooltip } from '../../../components/tooltip';
import { ctaTypeData, isCTA, isQuickReply, quickReplyTypeData, standardTypeData } from './templateAdapter';

function Template({ onChange, value, selectedLanguage }) {
  const { templates, getVarUserFields } = useBackend();
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [type, setType] = useState(null);
  useEffect(() => {
    setTemplateList(templates);
    setSelectedTemplate(null);
    setType(null);
    onChange('template', null);
  }, [templates, selectedLanguage]);

  useEffect(() => {
    setSelectedTemplate(templates?.find((item) => item.name === value?.label));
  }, [value]);

  const onChangeTemplateLanguage = (item) => {
    const selectedTemplateData = getMessageType(item);
    if (selectedTemplateData?.value) {
      setSelectedTemplate(item);
      onChange('template', selectedTemplateData);
    }
    getVarUserFields();
  };

  const getMessageType = (item) => {
    if (isQuickReply(item)) {
      setType('Quick Reply');

      return quickReplyTypeData(item);
    } else if (isCTA(item)) {
      setType('CTA Template');
      return ctaTypeData(item);
    } else {
      setType('Standard');
      return standardTypeData(item);
    }
  };

  const onSearchTemplates = (value) => {
    if (value.length > 0) {
      const matchedOptions = templates?.filter((option) => {
        return option.name.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1;
      });
      setTemplateList(matchedOptions);
    } else {
      setTemplateList(templates);
    }
  };

  return (
    <Container>
      <Dropdown
        selectedItem={selectedTemplate}
        onSelect={(item) => onChangeTemplateLanguage(item)}
        downshiftProps={{ itemToString: (item) => item && item.name }}
        onInputValueChange={(value) => onSearchTemplates(value)}
      >
        <Field>
          <SelectLabelContainer>
            <SelectLabel>Select Template</SelectLabel>
            <InfoTooltip tooltip="Select your pre-approved WhatsApp template message" />
          </SelectLabelContainer>
          <Autocomplete disabled={false} isCompact>
            {selectedTemplate?.name}
          </Autocomplete>
        </Field>

        <Menu>
          {templates && templateList ? (
            templateList.length === 0 ? (
              <Item disabled>No Such Template</Item>
            ) : (
              templateList.map((option) => (
                <Item key={option.id} value={option}>
                  {option.name}
                </Item>
              ))
            )
          ) : (
            <Item disabled>Loading...</Item>
          )}
        </Menu>
      </Dropdown>

      {selectedTemplate && type && (
        <TagContainer>
          <Tag>{type}</Tag>
        </TagContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  gap: 11px;
`;

const SelectLabel = styled(Label)`
  color: #03363d;
  font-size: 12px;
  font-weight: 700;
`;

const SelectLabelContainer = styled(Label)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;

  //because Select.Value already have margin-top: 4px;
  // margin-bottom: 7px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

//To reset grid stretch
const TagContainer = styled.div``;

const Tag = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: unset;

  padding: 1px 8px;

  background: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 2px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #1890ff;
`;

export default Template;
