import React from 'react';

import { Dropdown, Field, Menu, Item, Select, Label } from '@zendeskgarden/react-dropdowns';
import { useBackend } from '../../../api/BackendApiService';
import styled from 'styled-components';
import { InfoTooltip } from '../../../components/tooltip';

function Language({ onChangeLanguage, selectedLanguage }) {
  const { languages } = useBackend();

  return (
    <Dropdown
      selectedItem={selectedLanguage}
      onSelect={(item) => onChangeLanguage(item)}
      downshiftProps={{ itemToString: (item) => item && item }}
    >
      <Field>
        <SelectLabelContainer>
          <SelectLabel>Select Template Language</SelectLabel>
          <InfoTooltip tooltip="Choose the language submitted for the pre-approved template message you want to use" />
        </SelectLabelContainer>

        <Select isCompact disabled={false}>
          {selectedLanguage?.toUpperCase()}
        </Select>
      </Field>

      <Menu>
        {languages ? (
          languages.map((option) => (
            <Item key={option} value={option}>
              {option.toUpperCase()}
            </Item>
          ))
        ) : (
          <Item disabled>Loading...</Item>
        )}
      </Menu>
    </Dropdown>
  );
}

const SelectLabel = styled(Label)`
  color: #03363d;
  font-size: 12px;
  font-weight: 700;
`;

const SelectLabelContainer = styled(Label)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

export default Language;
