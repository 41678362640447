const PDF_FILE_TYPE = 'application/pdf';

const acceptImages = {
  'image/*': ['.jpeg', '.png', '.gif'],
};
const acceptPDF = {
  [PDF_FILE_TYPE]: ['.pdf'],
};

const mediaMaxSize = {
  pdf: 50 * 1024 * 1024,
  image: 5 * 1024 * 1024,
};

export const RECURRENCE_TYPES = {
  month: 'MONTHLY',
  year: 'YEARLY',
};

export const PAYMENT_TYPE = {
  OneTime: 'one_time',
  Recurring: 'recurring',
};

export const SUBCRIPTION_TYPE = {
  invoice: 'invoice',
};

const MAX_INTERNATIONAL_PHONE_LENGTH = 18;

export const STATUS_FILTER_OPTION = {
  Sent: 'messageSent',
  Read: 'messageRead',
  Delivered: 'messageDelivered',
  Failed: 'failed',
};

export const SENT_STATUS_COLOR = 'rgba(23, 73, 77, 1)';
export const DELIVERED_STATUS_COLOR = 'rgba(51, 83, 226, 1)';
export const READ_STATUS_COLOR = 'rgba(3, 129, 83, 1)';
export const FAILED_STATUS_COLOR = 'rgba(199, 42, 28, 1)';

// App access event
export const MixPanelAppAccess = 'APP_ACCESS';

// Active agents event
export const MixPanelActiveAgents = 'ACTIVE_AGENTS';

// Payment success event
export const MixPanelPaymentSuccess = 'PAYMENT_SUCCESS';

// Payment success event
export const MixPanelInvoiceSent = 'INVOICE_SENT';

// Payment failed event
export const MixPanelPaymentFailed = 'PAYMENT_FAILED';

// Plan selection event
export const MixPanelPlanSelection = 'PAYMENT_INIT';

// Message sent event
export const MixPanelMessageSent = 'MESSAGE_SENT';

export { acceptImages, acceptPDF, mediaMaxSize, PDF_FILE_TYPE, MAX_INTERNATIONAL_PHONE_LENGTH };
