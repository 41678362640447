const varsOrder = ['header', 'body', 'footer', 'buttons'];

export const isQuickReply = (item) => {
  return (
    item &&
    item?.components?.some(
      (component) =>
        component?.type === 'BUTTONS' && component?.buttons.some((button) => button?.type === 'QUICK_REPLY')
    )
  );
};

export const isCTA = (item) => {
  return (
    item &&
    item?.components?.some(
      (component) =>
        component?.type === 'BUTTONS' &&
        component?.buttons.some((button) => button?.type === 'PHONE_NUMBER' || button?.type === 'URL')
    )
  );
};

export const quickReplyTypeData = (item) => {
  if (item) {
    const base = {
      label: item.name,
      value: item.id,
      vars: getVariables(item),
      original: item,
    };
    return {
      ...base,
      type: 'quickReply',
      content: {
        text: getTextFromTemplate(item),
        media: isMedia(item),
        isPdfMedia: isPdfMedia(item),
        buttons: getButtons(item),
      },
    };
  }
};

export const ctaTypeData = (item) => {
  if (item) {
    const base = {
      label: item.name,
      value: item.id,
      vars: getVariables(item),
      original: item,
    };
    return {
      ...base,
      type: 'CTA',
      content: {
        text: getTextFromTemplate(item),
        media: isMedia(item),
        isPdfMedia: isPdfMedia(item),
        buttons: getButtons(item),
      },
    };
  }
};

export const standardTypeData = (item) => {
  if (item) {
    const base = {
      label: item.name,
      value: item.id,
      vars: getVariables(item),
      original: item,
    };
    return {
      ...base,
      type: 'text',
      content: {
        text: getTextFromTemplate(item),
        media: isMedia(item),
        isPdfMedia: isPdfMedia(item),
      },
    };
  }
};

export const getTextFromTemplate = (template, separator = '\n\n') => {
  const componentsWithTexts = template?.components?.filter(hasText);

  const shiftMap = getVarsShift(template?.variables);

  return componentsWithTexts
    ?.map(({ type, text }) => {
      const varKey = type?.toLowerCase();
      const needToReplaceCount = template?.variables[varKey] ?? 0;

      if (type === 'HEADER') {
        text = `<b>${text}</b>`;
      } else if (type === 'FOOTER') {
        text = `<span style='color: #999'>${text}</span>`;
      }
      return Array.from({ length: needToReplaceCount }).reduce((replacedText, _, index) => {
        const realIndex = needToReplaceCount - index;

        const varName = varNameToTemplateVarName(`${realIndex}`);
        const resolvedVarName = varNameToTemplateVarName(`${realIndex + shiftMap[varKey]}`);

        return replacedText.replace(varName, resolvedVarName);
      }, text);
    })
    .join(separator);
};

const hasText = (component) => {
  const hasTextStringProp = (obj) => 'text' in obj && typeof obj.text === 'string';

  if (component?.type === 'HEADER') {
    return component?.format === 'TEXT' && hasTextStringProp(component);
  }

  if (component?.type === 'FOOTER' || component?.type === 'BODY') {
    return hasTextStringProp(component);
  }

  return hasTextStringProp(component);
};

const getVarsShift = (variables) => {
  const valueOrZero = (value) => value ?? 0;

  const header = 0;
  const body = valueOrZero(variables?.header);
  const footer = body + valueOrZero(variables?.body);
  const buttons = footer + valueOrZero(variables?.footer);

  return { header, body, footer, buttons };
};

const varNameToTemplateVarName = (name) => {
  return `{{${name}}}`;
};

const isMedia = (template) =>
  template?.components?.some(
    (component) => component?.type === 'HEADER' && (component?.format === 'IMAGE' || component?.format === 'DOCUMENT')
  );

const isPdfMedia = (template) =>
  template?.components?.some((component) => component?.type === 'HEADER' && component?.format === 'DOCUMENT');

const getButtons = (component) => {
  const { components } = component;
  const buttons = components?.find((component) => component?.type === 'BUTTONS');

  if (!buttons) {
    return [];
  }

  return buttons?.buttons.map((button) => ({
    text: button?.text,
    icon: getButtonType(button),
  }));
};

const getButtonType = (button) => {
  if (button?.type === 'URL') {
    return 'URL';
  }

  if (button?.type === 'PHONE_NUMBER') {
    return 'PHONE';
  }

  return undefined;
};

const getVariables = (template) =>
  Object.entries(template?.variables || {}).reduce((vars, [key, length]) => {
    if (!length) return vars;

    if (key !== 'buttons') {
      Array.from({ length }).forEach((_) => {
        vars.push({
          name: `${vars.length + 1}`,
          placeholder: '',
          isUrl: false,
          value: '',
        });
      });

      return vars;
    }

    //assume if we have variables for buttons, component with type='BUTTONS' exist
    const { buttons } =
      template?.components && template?.components?.find((component) => component?.type === 'BUTTONS');

    Array.from({ length }).forEach((_, varIndex) => {
      //arr.findIndex because of `ts(2349). This expression is not callable. Has signatures, but none of those signatures are compatible with each other`
      const button =
        //assume if variable for button exist we can find it
        buttons[
          buttons.findIndex((button) => {
            if (button.type === 'URL') {
              return button?.url.includes(varNameToTemplateVarName(varIndex + 1));
            }

            if (button.type === 'PHONE_NUMBER') {
              return button?.phoneNumber?.includes(varNameToTemplateVarName(varIndex + 1));
            }

            return button?.text?.includes(varNameToTemplateVarName(varIndex + 1));
          })
        ];

      vars.push(
        button?.type === 'URL'
          ? {
              name: `${vars.length + 1}`,
              placeholder: '',

              isUrl: true,
              value: '',
              //assume URL can include only one variable and only in the end
              urlPrefix: button?.url.replace(varNameToTemplateVarName(varIndex + 1), ''),
            }
          : {
              name: `${vars.length + 1}`,
              placeholder: '',
              isUrl: false,
              value: '',
            }
      );
    });

    return vars;
  }, []);

export const replateTextWithVarsValue = (templateText, vars) => {
  return Object.entries(vars).reduce((text, [varName, varValue]) => {
    if (!varValue?.value) return text;
    const varReplacedWith = varValue?.value.includes('<')
      ? varValue?.value.replace('<', '').replace('>', '')
      : `{${varValue?.value}}`;
    return text.replace(varNameToTemplateVarName((parseInt(varName) + 1).toString()), varReplacedWith);
  }, templateText);
};

export const ToContractVars = (variables, templateVars) => {
  const varsDto = { bodyVariables: [], buttonVariables: [], headerVariables: [] };

  const shiftMap = getVarsShift(variables);

  varsOrder.forEach((varKey) => {
    const count = variables[varKey];

    if (!count) return;

    const shift = shiftMap[varKey];

    Array.from({ length: count }).forEach((_, index) => {
      const key = varKey == 'buttons' ? 'buttonVariables' : `${varKey}Variables`;
      //@ts-expect-error footerParams not declared, but checked after
      const params = varsDto[key];

      if (!Array.isArray(params)) return;

      const templateVar = templateVars[shift + index];
      const varName = templateVar?.name;
      params.push(
        templateVar?.isUrl
          ? { [varName]: templateVar?.value.replace(templateVar.urlPrefix, '') }
          : { [varName]: templateVar?.value }
      );
    });
  });

  return varsDto;
};
