import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import ZAFClient from 'zendesk_app_framework_sdk';
import { openPaymentModal, openZendeskModal } from './ZendeskApiService';
import moment from 'moment';
const zafClient = ZAFClient.init();

const BASE_URL = process.env.REACT_APP_API_KEY;

export const backendApiContext = createContext({
  languages: null,
  setLanguages: () => {},
  channels: null,
  setChannels: () => {},
  templates: null,
  setTemplates: () => {},
  authData: null,
  setAuthData: () => {},
  tags: null,
  setTags: () => {},
  agents: null,
  setAgents: () => {},
});

export function BackendApiProvider({ children }) {
  const backend = useBackendApi();
  // Return context provider to use in proejct
  return <backendApiContext.Provider value={backend}>{children}</backendApiContext.Provider>;
}

export const useBackend = () => {
  return useContext(backendApiContext);
};

// Functions to call all api used in project
const useBackendApi = () => {
  const [authData, setAuthData] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [channels, setChannels] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [tags, setTags] = useState(null);
  const [agents, setAgents] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [plans, setPlans] = useState(null);
  const [varFields, setVarFields] = useState(null);
  const [billingDetails, setBillingDetails] = useState(null);

  const showErrorNotification = async (error, title) => {
    let description;
    let button = null;
    const { currentUser } = await zafClient.get('currentUser');
    const { account } = await zafClient.context();
    switch (error.status) {
      case 402: {
        title = 'Trial Expired';
        description = 'Please buy a license to continue';
        button = { text: 'Buy', onClick: () => openPaymentModal() };
        break;
      }
      case 400: {
        description = error?.responseJSON?.message;
        break;
      }
      case 401:
      case 403: {
        description = error.responseJSON.message;
        if (error.responseJSON.code === 'AccessDenied') {
          title = 'Unable to access';
          description = `Oops! Seems like you do not have access for Chat Inc Proactive. Please request your admin (${error.responseJSON.data.adminEmail}) to grant you access.`;
          if (currentUser?.role === 'admin') {
            description = 'Oops! Seems like you do not have access for Chat Inc Proactive.';
            button = { text: 'Manage Agents', onClick: () => openZendeskModal() };
          }
        }
        break;
      }
      case 500:
      case 502: {
        description = 'Internal Server Error! Please report this error to ChatInc @ zendesk@chatinc.com.';
        break;
      }
      default:
        description = 'Check your internet connection and refresh widget to try again';
    }

    const notificationObject = {
      title,
      description: `${description} (${account?.subdomain})`,
      button,
      mode: 'error',
      hideClose: true,
    };
    setNotification(notificationObject);
  };

  //To get My activity
  const getMyActivity = async ({
    page,
    search = null,
    startDate = null,
    endDate = null,
    status = null,
    perPage,
    channelId,
  }) => {
    let url = `${BASE_URL}/summary/my-activity?page=${page}&perPage=${perPage}`;
    if (search) url += `&search=${search}`;
    if (startDate) url += `&startDate=${moment(startDate).format('YYYY/MM/DD')}`;
    if (endDate) url += `&endDate=${moment(endDate).format('YYYY/MM/DD')}`;
    if (status) url += `&status=${status}`;
    if (channelId) url += `&channelId=${channelId}`;

    try {
      const res = await zafClient.request({
        url,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      });
      return res.data;
    } catch (error) {
      showErrorNotification(error, 'Couldn’t get Activity');
      return null;
    }
  };

  //To export my activity
  const exportMyActivity = async (fileType, startDate = null, endDate = null, channelId) => {
    try {
      let url = `${BASE_URL}/summary/my-activity/export/?fileType=${fileType}`;
      if (startDate) url += `&startDate=${moment(startDate).format('YYYY/MM/DD')}`;
      if (endDate) url += `&endDate=${moment(endDate).format('YYYY/MM/DD')}`;
      if (channelId) url += `&channelId=${channelId}`;
      const res = await zafClient.request({
        url: url,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      });
      return res.data;
    } catch (error) {
      showErrorNotification(error, 'Couldn’t export Activity');
      return null;
    }
  };

  //To get summary of all agents
  const getAgentSummary = async (channelId) => {
    let url = `${BASE_URL}/summary/agents?`;
    if (channelId) url += `&channelId=${channelId}`;
    try {
      const res = await zafClient.request({
        url,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      });
      return res.data;
    } catch (error) {
      showErrorNotification(error, 'Couldn’t get Agent Summary');
      return null;
    }
  };

  //To export summary of all agents
  const exportAgentsSummary = async (fileType, channelId) => {
    try {
      let url = `${BASE_URL}/summary/agents/export?fileType=${fileType}`;
      if (channelId) url += `&channelId=${channelId}`;
      const res = await zafClient.request({
        url: url,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      });
      return res.data;
    } catch (error) {
      showErrorNotification(error, 'Couldn’t export Agent Activity');
      return null;
    }
  };

  //To get activity of specific agent
  const getAgentActivity = async ({
    agentId,
    page,
    search = null,
    startDate = null,
    endDate = null,
    status = null,
    perPage,
    channelId,
  }) => {
    let url = `${BASE_URL}/summary/agents/${agentId}?page=${page}&perPage=${perPage}`;
    if (search) url += `&search=${search}`;
    if (startDate) url += `&startDate=${moment(startDate).format('YYYY/MM/DD')}`;
    if (endDate) url += `&endDate=${moment(endDate).format('YYYY/MM/DD')}`;
    if (status) url += `&status=${status}`;
    if (channelId) url += `&channelId=${channelId}`;

    try {
      const res = await zafClient.request({
        url,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      });
      return res.data;
    } catch (error) {
      showErrorNotification(error, 'Couldn’t get Agent Activity');
      return null;
    }
  };

  //To export activity of specific agent
  const exportAgentActivity = async (agentId, fileType, startDate = null, endDate = null, channelId) => {
    try {
      let url = `${BASE_URL}/summary/agents/${agentId}/export?fileType=${fileType}`;
      if (startDate) url += `&startDate=${moment(startDate).format('YYYY/MM/DD')}`;
      if (endDate) url += `&endDate=${moment(endDate).format('YYYY/MM/DD')}`;
      if (channelId) url += `&channelId=${channelId}`;

      const res = await zafClient.request({
        url,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      });
      return res.data;
    } catch (error) {
      showErrorNotification(error, 'Couldn’t export Agent Activity');
      return null;
    }
  };

  //To get all bulk send summary data
  const getCampaignList = async ({ page, search = null, startDate = null, endDate = null, perPage, channelId }) => {
    let url = `${BASE_URL}/summary/campaigns?page=${page}&perPage=${perPage}`;
    if (search) url += `&search=${search}`;
    if (startDate) url += `&startDate=${moment(startDate).format('YYYY/MM/DD')}`;
    if (endDate) url += `&endDate=${moment(endDate).format('YYYY/MM/DD')}`;
    if (channelId) url += `&channelId=${channelId}`;

    try {
      const res = await zafClient.request({
        url,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      });
      return res.data;
    } catch (error) {
      showErrorNotification(error, 'Couldn’t get Campaign List');
      return null;
    }
  };

  //To export data of specific campaign
  const exportCampaignData = async (id, fileType) => {
    try {
      let url = `${BASE_URL}/summary/campaigns/${id}/export/${fileType}`;
      const res = await zafClient.request({
        url,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      });

      return res.data;
    } catch (error) {
      showErrorNotification(error, 'Couldn’t export Campaign Data');
      return null;
    }
  };

  // To loggin user and get their data
  const userLogin = async () => {
    const { currentUser } = await zafClient.get('currentUser');
    const { account } = await zafClient.context();
    const { settings, appId } = await zafClient.metadata();
    setAuthData(null);
    zafClient
      .request({
        url: `${BASE_URL}/auth/login`,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          email: '{{setting.email}}',
          apiToken: '{{setting.token}}',
          domain: account?.subdomain,
          jobTitle: settings.job_title || null,
          companySize: null,
          agentId: currentUser.id || null,
        }),
        secure: true,
      })
      .then(async (res) => {
        if (settings?.job_title && res?.auth?.jobTitle !== settings?.job_title) {
          // To update zendek support app data
          await zafClient.request({
            url: `${BASE_URL}/auth/update`,
            type: 'PUT',
            contentType: 'application/json',
            headers: {
              'Authorization': `Bearer ${res.access_token}`,
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              email: '{{setting.email}}',
              apiToken: '{{setting.token}}',
              domain: account?.subdomain,
              jobTitle: settings?.job_title,
              companySize: null,
            }),
            secure: true,
          });
        }
        if (!res?.auth?.timezone) {
          const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
          updateTimeZone(tz);
        }

        localStorage.setItem('auth', res?.access_token);
        if (res?.isActive) {
          localStorage.setItem('channel', JSON.stringify(res?.channel));
          localStorage.setItem('language', res?.channel?.templateDefaultLanguage);
        }
        res['appId'] = appId;
        setAuthData(res);
      })
      .catch((error) => {
        console.log('User Login Error : ', error);
        let description = 'Please ensure your settings are correct and try again';
        let title = 'Couldn’t get data from Chat Inc';
        let button = null;
        if (error.status === 402) {
          title = 'Trial Expired';
          description = 'Please buy a license to continue';
          button = { text: 'Buy', onClick: () => openPaymentModal() };
        } else if (error.status < 500) {
          if (
            error?.responseJSON?.code === 'DuplicateDomainException' ||
            error?.responseJSON?.code === 'DuplicateAdminException'
          )
            title = 'Duplicate Account Existed';
          description = error?.responseJSON?.message;
        } else if (error.status === 500 || error.status === 502) {
          description = 'Internal Server Error! Please report this error to ChatInc @ zendesk@chatinc.com.';
        } else
          description = `Internal Server Error! Please report this error to ChatInc @ zendesk@chatinc.com. error -- ${error}`;

        const notificationObject = {
          title: title,
          description: `${description} (${account?.subdomain})`,
          button: button,
          mode: 'error',
          hideClose: true,
        };
        setNotification(notificationObject);
      });
  };

  // To register channel
  const register = async (params) => {
    setLoading(true);
    return zafClient
      .request({
        url: `${BASE_URL}/channels`,
        type: 'POST',
        data: JSON.stringify(params),
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('auth')}`,
          'Content-Type': 'application/json',
        },
        secure: true,
      })
      .then((res) => {
        setLoading(false);
        if (authData?.channel) {
          const notificationObject = {
            title: 'Settings saved successfully',
            description: 'Your settings have been saved successfully',
            button: { text: 'Continue to app', onClick: 'yes' },
            mode: 'success',
            hideClose: true,
          };
          setNotification(notificationObject);
          return false;
        }
        return res;
      })
      .catch((error) => {
        setLoading(false);
        console.log('Error : ', error);
        let message = 'Double check that you have entered all the information correctly.';
        const res = error.error ? error.error?.responseJSON : error.responseJSON;
        if (res?.error?.description) {
          message = `Smooch Error: ${res?.error?.description}`;
        } else if (res && typeof res?.error?.description === 'string') {
          message = `Error: ${res?.error?.description}`;
        } else if (res?.error) {
          message = `Error: ${res?.error}`;
        }
        const notificationObject = {
          title: 'Couldn’t update settings',
          description: message,

          button: { text: 'Dismiss' },
          mode: 'error',
        };
        setNotification(notificationObject);
      });
  };

  // To save settings
  const save = async (params) => {
    setLoading(true);
    zafClient
      .request({
        url: `${BASE_URL}/channels/save`,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(params),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
      .then((res) => {
        const notificationObject = {
          title: 'Settings updated successfully',
          description: 'Your settings have been updated successfully',

          button: { text: 'Continue', onClick: 'yes' },
          mode: 'success',
          hideClose: true,
        };
        setNotification(notificationObject);
        setLoading(false);
      })
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t update settings');
        setLoading(false);
      });
  };

  const getChannels = async () => {
    const channels = await zafClient.request({
      url: `${BASE_URL}/channels`,
      type: 'GET',
      contentType: 'application/json',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth')}`,
      },
    });
    return channels;
  };

  const getLanguages = async (channelId) => {
    const query = new URLSearchParams({
      channelId: channelId ?? JSON.parse(localStorage.getItem('channel'))?.channelId,
    });
    setTemplates(null);
    // TODO re-login so that token/channel gets refreshed in localStorage
    zafClient
      .request({
        url: `${BASE_URL}/templates/sweesh/languages?${query}`,
        type: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        secure: true,
      })
      .then((res) => {
        setLanguages(res.data);
      })
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t get languages for channel');
      });
  };

  const getTags = async (search) => {
    let url = `${BASE_URL}/common/tags`;
    if (search) url = url + `?search=${search}`;
    zafClient
      .request({
        url: url,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      })
      .then((res) => {
        setTags(res.tags);
      })
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t get Tags');
      });
  };

  const getTemplates = async (language, channelId) => {
    const query = new URLSearchParams({
      language: language,
      channelId: channelId,
    });
    zafClient
      .request({
        url: `${BASE_URL}/templates/sweesh?${query}`,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      })
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t get templates');
      });
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios({
      url: '/files/public/upload',
      baseURL: BASE_URL,
      method: 'POST',
      data: formData,
      formData: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('auth')}`,
      },
    });
    return response;
  };

  const sendMessage = async (dto, channel) => {
    const { currentUser } = await zafClient.get('currentUser');
    const response = await zafClient.request({
      url: `${BASE_URL}/messages`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        ...dto,
        agentId: `${currentUser.id}`,
        channelId: channel?.id?.toString(),
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth')}`,
      },
      secure: true,
    });
    return response;
  };

  const getAgents = async (page = 1) => {
    zafClient
      .request({
        url: `${BASE_URL}/subscription/agents?page=${page}`,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      })
      .then((res) => {
        setAgents(res.data);
      })
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t get Agent');
      });
  };

  const UpdateAgentAccess = async (data) => {
    const response = await zafClient.request({
      url: `${BASE_URL}/subscription/update-agent-access`,
      type: 'PUT',
      contentType: 'application/json',
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth')}`,
      },
      secure: true,
    });
    return response;
  };

  const getPlans = () => {
    zafClient
      .request({
        url: `${BASE_URL}/stripe/plans`,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      })
      .then((res) => {
        setPlans(res.data);
      })
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t get Plans');
      });
  };

  const getClientSecret = async (data, method) => {
    const clientSecret = await zafClient.request({
      url: `${BASE_URL}/subscription`,
      type: method,
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth')}`,
      },
      contentType: 'application/json',
      secure: true,
    });
    return clientSecret;
  };

  const getVarUserFields = () => {
    zafClient
      .request({
        url: `${BASE_URL}/common/user-fields`,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      })
      .then((res) => {
        setVarFields(res.data);
      })
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t get Variable fields');
      });
  };

  const updateTimeZone = (timeZone) => {
    let payload = { timezone: timeZone };
    zafClient
      .request({
        url: `${BASE_URL}/auth/timezone`,
        type: 'PATCH',
        data: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      })
      .then((res) => {})
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t update TimeZone');
      });
  };

  const cancelSubcriptionApi = () => {
    zafClient
      .request({
        url: `${BASE_URL}/subscription/cancel-subscription`,
        type: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      })
      .then((res) => {
        const notificationObject = {
          title: 'Success',
          description: 'Subscription has been cancelled successfully.',
          button: { text: 'Ok', onClick: () => zafClient.invoke('destroy') },
          mode: 'success',
          hideClose: true,
        };
        setNotification(notificationObject);
      })
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t cancel subscription');
      });
  };

  const cancelSubscriptionCancellation = () => {
    zafClient
      .request({
        url: `${BASE_URL}/subscription/cancel-subscription-cancellation`,
        type: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      })
      .then((res) => {
        const notificationObject = {
          title: 'Success',
          description: 'Cancellation has been retract successfully.',
          button: { text: 'Ok', onClick: () => zafClient.invoke('destroy') },
          mode: 'success',
          hideClose: true,
        };
        setNotification(notificationObject);
      })
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t cancel subscription cancellation');
      });
  };

  const payByInvoiceApi = async (data = null, path) => {
    try {
      const invoiceResult = await zafClient.request({
        url: `${BASE_URL}/subscription/${path}`,
        type: 'POST',
        data: data && JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      });
      return invoiceResult;
    } catch (error) {
      let title = 'Couldn’t able to pay invoice';
      if (path === 'resend-invoice') title = 'Couldn’t send email';
      showErrorNotification(error, title);
    }
  };

  const invoiceCancellationApi = async (path, cancelledAt) => {
    zafClient
      .request({
        url: `${BASE_URL}/subscription/${path}`,
        type: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      })
      .then((res) => {
        let description = `Your subscription has been cancelled and will take affect from ${moment(cancelledAt).format(
          'DD-MM-YYYY'
        )}`;
        if (path === 'cancel-invoice-cancellation') description = 'Invoice cancellation has been retract successfully.';
        const notificationObject = {
          title: 'Success',
          description: description,
          button: { text: 'Ok', onClick: () => zafClient.invoke('destroy') },
          mode: 'success',
          hideClose: true,
        };
        setNotification(notificationObject);
      })
      .catch((error) => {
        let title = 'Couldn’t cancel invoice';
        if (path === 'cancel-invoice-cancellation') title = 'Couldn’t cancel invoice cancellation';
        showErrorNotification(error, title);
      });
  };

  const helpRequestApi = async (payload) => {
    const response = zafClient.request({
      url: `${BASE_URL}/help-request`,
      type: 'POST',
      data: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('auth')}`,
      },
      contentType: 'application/json',
    });
    return response;
  };

  const getBillingDetails = async () => {
    zafClient
      .request({
        url: `${BASE_URL}/subscription/billing-detail`,
        type: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
        contentType: 'application/json',
        secure: true,
      })
      .then((res) => {
        setBillingDetails(res.data);
      })
      .catch((error) => {
        showErrorNotification(error, 'Couldn’t get Billing Details');
      });
  };

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    !authData && (await userLogin());
  };

  return {
    authData,
    languages,
    channels,
    templates,
    tags,
    agents,
    plans,
    billingDetails,
    getClientSecret,
    getPlans,
    setPlans,
    setAgents,
    setTags,
    setAuthData,
    setChannels,
    setLanguages,
    setTemplates,
    userLogin,
    getChannels,
    getLanguages,
    getTemplates,
    getTags,
    register,
    loading,
    save,
    notification,
    setNotification,
    uploadFile,
    sendMessage,
    getAgents,
    UpdateAgentAccess,
    getVarUserFields,
    varFields,
    updateTimeZone,
    cancelSubcriptionApi,
    cancelSubscriptionCancellation,
    payByInvoiceApi,
    invoiceCancellationApi,
    getMyActivity,
    exportMyActivity,
    getCampaignList,
    exportCampaignData,
    getAgentSummary,
    exportAgentsSummary,
    getAgentActivity,
    exportAgentActivity,
    helpRequestApi,
    getBillingDetails,
  };
};
