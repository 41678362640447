import React from 'react';

import { Dropdown, Field, Menu, Item, Select, Label } from '@zendeskgarden/react-dropdowns';
import { useBackend } from '../../../api/BackendApiService';
import styled from 'styled-components';
import { InfoTooltip } from '../../../components/tooltip';

function Channel({ onChannelChange, selectedChannel }) {
  const { channels } = useBackend();

  return (
    <Dropdown
      selectedItem={selectedChannel}
      onSelect={(item) => onChannelChange(item)}
      downshiftProps={{ itemToString: (item) => item && item }}
    >
      <Field>
        <SelectLabelContainer>
          <SelectLabel>Select Channel</SelectLabel>
          <InfoTooltip tooltip="Choose the channel through which you want to send message" />
        </SelectLabelContainer>

        <Select isCompact disabled={false}>
          {selectedChannel?.name}
        </Select>
      </Field>

      <Menu>
        {channels ? (
          channels
            .filter((ele) => ele.isSetup)
            .map((option) => (
              <Item key={option._id} value={option}>
                {option?.name}
              </Item>
            ))
        ) : (
          <Item disabled>Loading...</Item>
        )}
      </Menu>
    </Dropdown>
  );
}

const SelectLabel = styled(Label)`
  color: #03363d;
  font-size: 12px;
  font-weight: 700;
`;

const SelectLabelContainer = styled(Label)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

export default Channel;
