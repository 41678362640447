import { Anchor, Button } from '@zendeskgarden/react-buttons';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Field as InputField, Input, Label as InputLabel, Textarea } from '@zendeskgarden/react-forms';
import { useBackend } from '../../api/BackendApiService';
import {
  Autocomplete,
  Dropdown,
  Field,
  Hint,
  Item,
  Label,
  Menu,
  Message,
  Select,
} from '@zendeskgarden/react-dropdowns';
import { StatusIndicator } from '@zendeskgarden/react-avatars';
import { InfoTooltip } from '../../components/tooltip';
import Notification from '../../components/notification';
import Sunco from './SuncoSettings';
import ChannelSettings from './channelSettings';
import { openZendeskModal } from '../../api/ZendeskApiService';
import { Stepper } from '@zendeskgarden/react-accordions';
import { Col, Row } from '@zendeskgarden/react-grid';
import { Spinner } from '@zendeskgarden/react-loaders';

const imgPlaceholder = '/payment_success1.jpg';

function SettingsUi() {
  const { channels, notification, updateTimeZone, authData, loading, helpRequestApi, setNotification, userLogin } =
    useBackend();
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showChannelSetup, setShowChannelSetup] = useState(true);
  const [clickNo, setClickNo] = useState(false);
  const [formValue, setFormValue] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const naviagte = useNavigate();
  const {
    state: { clientData },
  } = useLocation();
  const lastPage =
    clientData.location === 'user_sidebar'
      ? '/zd-user'
      : clientData.location === 'ticket_sidebar'
      ? '/zd-tickets'
      : '/zd-modal';

  useEffect(() => {
    if (!authData?.auth?.timezone) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      setSelectedTimeZone(tz);
    } else {
      setSelectedTimeZone(authData?.auth?.timezone);
    }
    if (!authData?.channel) setShowChannelSetup(false);
    var timeZones = Intl.supportedValuesOf('timeZone');
    setTimeZones(timeZones);
  }, []);

  useEffect(() => {
    setSelectedChannel(channels?.find((ch) => ch.isDefault));
  }, [channels]);

  const onChannelSelect = (item) => {
    setSelectedChannel(item);
  };

  const onChangeTimeZone = (item) => {
    setSelectedTimeZone(item);
    updateTimeZone(item);
  };

  const onSearchTimeZones = (value) => {
    if (value.length > 0) {
      const matchedOptions = timeZones.filter((option) => {
        return option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1;
      });
      setTimeZones(matchedOptions);
    } else {
      setTimeZones(Intl.supportedValuesOf('timeZone'));
    }
  };

  const chanelSetupComponents = () => {
    return (
      <Container>
        {showChannelSetup && (
          <>
            <Dropdown
              selectedItem={selectedTimeZone}
              onSelect={(item) => onChangeTimeZone(item)}
              downshiftProps={{ itemToString: (item) => item && item }}
              onInputValueChange={(value) => onSearchTimeZones(value)}
            >
              <Field>
                <SelectLabelContainer>
                  <SelectLabel>Select TimeZone</SelectLabel>
                  <InfoTooltip
                    setUp={true}
                    placement="end"
                    tooltip="The time of this time zone will be used to determine when your tier limit will start/end on a daily basis"
                  />
                </SelectLabelContainer>
                <Autocomplete disabled={false} isCompact>
                  {selectedTimeZone}
                </Autocomplete>
              </Field>

              <Menu>
                {timeZones ? (
                  timeZones.length === 0 ? (
                    <Item disabled>No Such timezone</Item>
                  ) : (
                    timeZones.map((option) => (
                      <Item key={option} value={option}>
                        {option}
                      </Item>
                    ))
                  )
                ) : (
                  <Item disabled>Loading...</Item>
                )}
              </Menu>
            </Dropdown>

            {showChannelSetup && (
              <Dropdown
                selectedItem={selectedChannel}
                onSelect={(item) => onChannelSelect(item)}
                downshiftProps={{ itemToString: (item) => item && item }}
              >
                <Field>
                  <SelectLabelContainer>
                    <SelectLabel>Select Channel</SelectLabel>
                    <InfoTooltip
                      setUp={true}
                      placement="end"
                      tooltip="Select the channel you want to view/change the settings of"
                    />
                  </SelectLabelContainer>

                  <Select isCompact>{selectedChannel ? selectedChannel?.displayName : 'Select channel'}</Select>
                  <Hint>
                    {!!selectedChannel && selectedChannel?.isSetup && (
                      <Message style={{ fontSize: '14px' }} validation="success">
                        Channel successfully set up
                      </Message>
                    )}
                  </Hint>
                </Field>

                <Menu>
                  {channels ? (
                    channels.map((option) => {
                      const type = option.isSetup ? 'available' : 'offline';
                      return (
                        <Item key={option._id} value={option}>
                          <StatusIndicator type={type} aria-label={`status: ${type}`}>
                            {`${option.displayName} ${option.phoneNumber ? '+' : ''}${option.phoneNumber}`}
                          </StatusIndicator>
                        </Item>
                      );
                    })
                  ) : (
                    <Item disabled>Loading...</Item>
                  )}
                </Menu>
              </Dropdown>
            )}

            {selectedChannel && !selectedChannel.isSetup && (
              <Sunco selectedChannel={selectedChannel} onSuccess={() => onClickYes('success')} />
            )}

            {selectedChannel && selectedChannel.isSetup && (
              <>
                <span
                  style={{
                    paddingBottom: '12px',
                    borderBottom: '1px solid #ccc',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#03363D',
                  }}
                >
                  Channel Settings
                </span>
                <ChannelSettings selectedChannel={selectedChannel} />
              </>
            )}
          </>
        )}
      </Container>
    );
  };

  const allSteps = [
    {
      label: 'Using Whatsapp on Zendesk?',
      content: 'Are you using WhatsApp on Zendesk at the moment ?',
      buttons: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton size="small" isPrimary onClick={() => onClickYes('step1')}>
            Yes
          </StyledButton>
          <StyledButton size="small" onClick={() => onClickNo('Need assistance with Whatsapp channel in Zendesk')}>
            No
          </StyledButton>
        </div>
      ),
    },
    {
      label: 'Do you have Sunshine Conversations?',
      content: 'Do you have Sunshine Conversations?',
      buttons: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledButton size="small" isPrimary onClick={() => onClickYes('step2')}>
            Yes
          </StyledButton>
          <StyledButton
            size="small"
            onClick={() => onClickNo('Need assistance with Sunshine Conversations integration')}
          >
            No
          </StyledButton>
          <StyledButton size="small" onClick={() => onClickNo("Don't know about Sunshine Conversations integration")}>
            I don't know
          </StyledButton>
        </div>
      ),
    },
    {
      label: 'Channel Setup',
      content: chanelSetupComponents(),
    },
    {
      label: 'Send message',
      content: (
        <div style={{ display: 'grid', justifyContent: 'center' }}>
          <StyledImage alt="" src={imgPlaceholder} />
          App has been set up successfully.
        </div>
      ),
      buttons: (
        <StyledButton
          size="small"
          onClick={() => {
            userLogin();
            naviagte(lastPage, { replace: true });
          }}
        >
          Send Your First Message
        </StyledButton>
      ),
    },
  ];

  const onClickYes = (step) => {
    if (step === 'step1') return setCurrentStep(currentStep + 1);
    if (step === 'step2') {
      console.log('second step');
      setCurrentStep(currentStep + 1);
      setShowChannelSetup(true);
      return;
    }
    if (step === 'success') {
      setCurrentStep(currentStep + 1);
      return;
    }
  };

  const onClickNo = (title) => {
    setClickNo(true);
    setFormValue((values) => ({ ...values, title: title }));
  };

  const onClickLable = (idx) => {
    if (currentStep !== allSteps.length - 1 && currentStep > idx) {
      setCurrentStep(idx);
    }
  };

  const onSaveForm = () => {
    let payload = {
      domain: authData?.auth?.domain,
      name: formValue['name'],
      contactEmail: formValue['email'],
      description: formValue['description'],
      title: formValue['title'],
    };
    helpRequestApi(payload)
      .then((res) => {
        const notificationObject = {
          title: 'Success',
          description: 'Your request has been submitted and Zendesk will reach out to you.',
          mode: 'success',
          button: { text: 'Ok', onClick: () => setClickNo(false) },
          hideClose: true,
        };
        setFormValue({});
        setNotification(notificationObject);
      })
      .catch((error) => {
        console.log('Error : ', error);
        let title = 'Couldn’t submit your request, please try again later.';
        let description = 'Check your internet connection and refresh widget to try again';
        if (error.status !== 500 && error.status !== 502) {
          description = error?.responseJSON?.message;
        }
        const notificationObject = {
          title: title,
          description: description,
          mode: 'error',
          hideClose: false,
        };
        setNotification(notificationObject);
      });
    return;
  };

  const onClickBack = () => {
    setFormValue({});
    setClickNo(false);
  };

  const inputValueChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormValue((values) => ({ ...values, [name]: value }));
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (name === 'email' && e.target.value?.match(isValidEmail)) {
      setValidEmail(true);
    } else if (name === 'email') {
      setValidEmail(false);
    }
  };

  if (notification)
    return (
      <CenterPageContainer>
        <Notification lastPage={lastPage} />
      </CenterPageContainer>
    );

  if (loading && !authData?.channel)
    return (
      <CenterPageContainer>
        <LoadingContainer>
          <Spinner size="60" color="#1f73b7" />
          {!authData?.channel && (
            <span style={{ color: '#03363d', fontSize: '14px' }}>
              Setting up your first channel, please wait for a while!
            </span>
          )}
        </LoadingContainer>
      </CenterPageContainer>
    );

  if (clickNo)
    return (
      <Container location={clientData.location}>
        <SeperatorLabel style={{ width: '50%' }}>Give us your details</SeperatorLabel>
        <InputField style={{ textAlign: 'left' }}>
          <InputLabel>
            <span style={{ color: '#03363d' }}>Name</span>
          </InputLabel>
          <Input
            isCompact
            name="name"
            value={formValue['name']}
            onChange={(event) => inputValueChange(event)}
            placeholder="Please enter your name"
          />
        </InputField>

        <InputField style={{ textAlign: 'left' }}>
          <InputLabel>
            <span style={{ color: '#03363d' }}>Email</span>
          </InputLabel>
          <Input
            isCompact
            type="email"
            name="email"
            value={formValue['email']}
            validation={validEmail ? 'success' : formValue['email']?.length > 0 ? 'error' : ''}
            onChange={(event) => inputValueChange(event)}
            placeholder="Please enter your email"
          />
        </InputField>

        <InputField style={{ textAlign: 'left' }}>
          <InputLabel>
            <span style={{ color: '#03363d' }}>Description</span>
          </InputLabel>
          <Textarea
            isResizable
            name="description"
            value={formValue['description']}
            onChange={(event) => inputValueChange(event)}
            placeholder="Please enter description (optional)"
          />
        </InputField>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledButton onClick={() => onClickBack()}>Back</StyledButton>

          <Container>
            <StyledButton
              isPrimary
              disabled={!validEmail || formValue['name']?.length === 0 || loading}
              onClick={() => onSaveForm()}
            >
              {loading && <Spinner />}
              <Text hidden={loading}>Submit</Text>
            </StyledButton>
          </Container>
        </div>
      </Container>
    );

  return (
    <Container location={clientData.location}>
      <>
        <div style={{ display: 'flex' }}>
          <SeperatorLabel style={{ width: '50%' }}>
            {authData?.channel ? <>Settings</> : <>Connect WhatsApp</>}
          </SeperatorLabel>
          {/* {authData?.channel && (
            <SuncoAnchor isExternal href="https://app.arcade.software/share/TEPoL5Y5eWgbGFxPo63b" target="_blank">
              Where to find this
            </SuncoAnchor>
          )} */}
        </div>
        {!authData?.channel && (
          <Row justifyContent="center" style={{ width: '100%' }}>
            <Col sm={10} textAlign="center">
              <Stepper activeIndex={currentStep}>
                {allSteps.map((step, idx) => (
                  <Stepper.Step key={`step-${idx + 1}`}>
                    <Stepper.Label
                      iconProps={{ stroke: '#00bff0', fill: '#00bff0' }}
                      onClick={() => onClickLable(idx)}
                      style={{ cursor: 'pointer' }}
                    >
                      {step.label}
                    </Stepper.Label>
                    <Stepper.Content style={{ padding: '0px 0px 32px 10px' }}>
                      <StyledContainer key={idx}>
                        {step.content}
                        <StyledButtonContainer>{step.buttons}</StyledButtonContainer>
                      </StyledContainer>
                    </Stepper.Content>
                  </Stepper.Step>
                ))}
              </Stepper>
            </Col>
          </Row>
        )}
        {!selectedChannel && showChannelSetup && authData?.channel && <span>Please complete the setup below:</span>}

        {authData?.channel && chanelSetupComponents()}

        <div style={{ marginTop: '20px' }}>
          {selectedChannel && selectedChannel?.isSetup && (
            <Anchor style={{ float: 'right', fontSize: '12px' }} isExternal onClick={() => openZendeskModal()}>
              Manage Agents
            </Anchor>
          )}
          <br></br>
          {/* {authData?.channel && (
            <Anchor
              style={{ float: 'right', fontSize: '12px' }}
              isExternal
              href="https://app.arcade.software/share/Ov859tsJ5bVkQCLbBGzl"
              target="_blank"
            >
              Need help setting up?
            </Anchor>
          )}
          <br></br> */}

          <VersionLabel>V {clientData?.version}</VersionLabel>
        </div>
      </>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  gap: 23px;
  padding: ${(props) => (props.location === 'top_bar' ? '20px !important' : '0px')};
`;

const SelectLabelContainer = styled(Label)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;

  //because Select.Value already have margin-top: 4px;
  // margin-bottom: 7px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

const SelectLabel = styled(Label)`
  color: #03363d;
  font-size: 12px;
  font-weight: 700;
`;

const SeperatorLabel = styled.span`
  padding-right: 5px;
  font-weight: 700;
  font-size: 16px;
  color: #03363d;
`;

const CenterPageContainer = styled.div`
  display: grid;
  place-items: center;
  flex: 1;
  margin-top: -23px;
`;

const VersionLabel = styled.span`
  float: left;
  font-size: 12px;
  color: #03363d;
  margin-top: 10px;
`;

const SuncoAnchor = styled(Anchor)`
  font-size: 12px;
  text-align: end;
  width: 50%;
  margin-right: 5px;
`;

const StyledButtonContainer = styled.div`
  margin-top: 20px;
  padding: ${(p) => p.theme.shadowWidths.md};

  & > button {
    margin-${(p) => (p.theme.rtl ? 'right' : 'left')}: ${(p) => p.theme.space.base * 3}px;

    &:first-child {
      margin-${(p) => (p.theme.rtl ? 'right' : 'left')}: 0;
    }
  }
`;

const StyledContainer = styled.div`
  margin: 50px 0 0 0;
  font-size: 14px;
  color: #03363d;
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  font-weight: 700;
  margin-right: 10px;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
`;

const StyledImage = styled.img`
  height: 200px;
  justify-self: center;
`;

const Text = styled.span`
  visibility: ${(props) => props.hidden && 'hidden'};
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 50px;
`;

export default SettingsUi;
