import { Anchor, Button } from '@zendeskgarden/react-buttons';
import { Field, Label, Toggle } from '@zendeskgarden/react-forms';
import { Inline } from '@zendeskgarden/react-loaders';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useBackend } from '../../api/BackendApiService';
import Language from '../../pages/send-message/language';
import { MessagePreview } from './message-preview';
import Tags from './tags';
import { ToContractVars } from './template/templateAdapter';
import TemplateVars from './templateVars';
import Notification from '../../components/notification';
import Template from './template';
import Phone from './phone';
import { openPaymentModal, zafClient } from '../../api/ZendeskApiService';
import { Mixpanel } from '../../components/MixPanel';
import { MixPanelMessageSent, SUBCRIPTION_TYPE } from './constants';
import { Tag } from '@zendeskgarden/react-tags';
import Channel from './channels';

const imgPlaceholder = '/gear-stroke.svg';
const invoiceDueDay = process.env.REACT_APP_INVOICE_DUE_DAYS;
let userCount = 0;

function SendMessageUi(props) {
  const { clientData } = props;
  const { authData, uploadFile, sendMessage, setNotification, notification, channels, getLanguages, getTemplates } =
    useBackend();
  const [diffDays, setDiffDays] = useState(null);
  const [bulkToggle, setBulkToggle] = useState(false);
  const [onlyHasOnePhone, setOnlyHasOnePhone] = useState(false);
  const [hasTicketId, sethasTicketId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [createTicket, setCreateTicket] = useState(false);
  const [payload, setPayload] = useState({
    language: null,
    template: null,
    vars: null,
    file: null,
    phone: null,
    tags: [],
  });
  const [notificationObj, setNotificationObj] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (!clientData) return;
    if (clientData?.location === 'ticket_sidebar' && !isValidTicket()) return;
    setSelectedChannel(channels?.find((item) => item.id === authData?.channel?.id));
    setOnlyHasOnePhone(clientData?.phones?.length === 1);
    sethasTicketId(clientData?.ticketId);
    setCreateTicket(authData?.auth?.defaultAllowCreateTicketValue);
    calculateDaysBeforeTrial();
    const requestPayload = { ...payload };
    requestPayload.language = authData?.channel?.templateDefaultLanguage;
    setSelectedLanguage(authData?.channel?.templateDefaultLanguage);
    setPayload(requestPayload);
  }, []);

  // To calculate days remaining before trial expier
  const calculateDaysBeforeTrial = () => {
    if (authData && !authData?.auth?.fullAccess && !diffDays) {
      let currentDate = new Date();
      let expiryDate = new Date(authData?.activatedOn);
      expiryDate.setDate(expiryDate.getDate() + parseInt(process.env.REACT_APP_TRIAL_PERIOD));
      const diffTime = expiryDate.getTime() - currentDate.getTime();
      let diffInDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffInDays < 0) diffInDays = 0;
      setDiffDays(diffInDays.toString());
    }
  };

  const isValidTicket = () => {
    let title;
    let description;

    if (clientData?.status === 'closed') {
      title = 'Ticket closed';
      description = 'You cannot send message from the closed ticket';
    } else if (clientData?.channel !== 'whatsapp' && clientData?.channel !== 'web') {
      title = 'Unsuported ticket';
      description = 'Proactive sidebar supports message sending for only Whatsapp and Web tickets';
    }

    if (title && description) {
      const notificationObject = {
        title,
        description,
        mode: 'success',
        hideClose: true,
      };

      setNotification(notificationObject);
      return false;
    }

    return true;
  };

  // To set all fields value
  const onFieldValueChange = (field, value) => {
    const requestPayload = { ...payload };
    requestPayload[field] = value;
    setPayload(requestPayload);
    setCanSubmit(isValidForm(requestPayload));
  };

  const isValidForm = (requestPayload) => {
    if (!requestPayload.language || !requestPayload.template) return false;

    if (requestPayload?.template?.vars?.length > 0) {
      if (!requestPayload.vars) return false;

      for (let i = 0; i < requestPayload?.vars?.length; i++) {
        const val = requestPayload.vars[i];
        if (val.isUrl && val.value?.length === val.urlPrefix?.length) return false;

        if (val.value?.length === 0 || val.value === '') return false;
      }
    }
    if (clientData?.location === 'ticket_sidebar' || clientData?.location === 'new_ticket_sidebar') return true;

    if (requestPayload.template?.content.media && !requestPayload.file) return false;
    if (bulkToggle && !requestPayload?.tags?.length) return false;
    if (!bulkToggle && !requestPayload.phone) return false;

    return true;
  };

  const onChangeBulkToggle = (status) => {
    setBulkToggle(status);

    if (status) {
      onFieldValueChange('phone', null);
      setCreateTicket(false);
    } else onFieldValueChange('tags', null);
  };
  const onSendMessage = () => {
    setLoading(true);
    let data = {
      locale: payload?.language,
      templateId: payload?.template?.value,
      userId: clientData.id,
      ...ToContractVars(payload.template.original.variables, payload.template.vars),
    };

    if (payload.tags) data.tags = payload.tags;
    if (payload.phone) data.phone = payload.phone;
    if (clientData?.location === 'ticket_sidebar' || clientData?.location === 'new_ticket_sidebar') {
      if (!bulkToggle && !payload.phone) data.phone = clientData?.phones[0]?.value;
    }

    if (clientData?.channel !== 'whatsapp' && authData?.auth?.allowCreateTicket) data.createTicket = createTicket;

    if (clientData?.ticketId) data.ticketId = clientData.ticketId;

    if (payload?.template?.content.media) {
      uploadFile(payload.file)
        .then((res) => {
          const url = res.data.data.publicUrl;
          payload?.template?.content.isPdfMedia
            ? (data.pdfUrl = new URL(url).href)
            : (data.imageUrl = new URL(url).href);

          sendMesageApiCall(data);
        })
        .catch((error) => {
          setLoading(false);
          const errorMsg = error?.response?.data;
          const notificationObject = {
            title: "Couldn't upload Image",
            description: `${errorMsg.message} (${authData?.auth?.domain})`,
            button: { text: 'Retry' },
            mode: 'error',
            hideClose: false,
          };
          setNotification(notificationObject);
          const requestPayload = { ...payload };
          Object.keys(requestPayload).map((val) => (requestPayload[val] = null));
          setPayload(requestPayload);
        });
    } else {
      sendMesageApiCall(data);
    }
  };

  const sendMesageApiCall = (data) => {
    setLoading(true);
    sendMessage(data, selectedChannel)
      .then((res) => {
        Mixpanel.track(MixPanelMessageSent, {
          Domain: authData.auth.domain,
          SentBy: clientData.user.email,
          AppLocation: clientData.location,
          TemplateType: payload.template.original.category,
          MessageType: data.phone && !data.tags?.length ? 'Single Send' : 'Bulk Send',
          UserCount: data.phone && !data.tags?.length ? null : userCount,
        });
        const notificationObject = {
          title: 'Template sent',
          description: 'Template message successfully sent to the user/s',
          button: { text: 'Send Another', onClick: 'yes' },
          mode: 'success',
          hideClose: true,
        };
        setLoading(false);
        setNotification(notificationObject);
        if (res?.data?.communication?.ticketId) zafClient.invoke('routeTo', 'ticket', res.data.communication.ticketId);
      })
      .catch((error) => {
        setLoading(false);
        let title = 'Couldn’t send message';
        let description = 'Check your internet connection and try again';
        let mode = 'error';
        const errorData = error.responseJSON;
        let button = { text: 'Retry', onClick: 'yes' };
        if (errorData?.code === 'WarningException') {
          title = 'Warning';
          if (errorData?.data?.userCount === 0) {
            description = 'The tag you selected is not tied to any users';
            button.text = 'Back';
            mode = 'success';
            const notificationObject = {
              title: title,
              description: description,
              button: button,
              mode: mode,
              hideClose: true,
              showNotification: true,
            };
            setCanSubmit(true);
            setNotificationObj(notificationObject);
            return;
          } else {
            description = errorData?.message;
            button.text = 'Send Anyway';
            mode = 'success';
            button.onClick = () => {
              data.force = true;
              userCount = errorData?.data?.userCount;
              sendMesageApiCall(data);
              setNotification(null);
            };
          }
        } else if (errorData?.code === 'TierLimitExceeded') {
          title = 'Tier Limit Exceeded';
          description = errorData.message;
          button.text = 'Back';
          button.onClick = () => {
            setNotification(null);
          };
        } else if (error.status === 403 || error.status === 401) {
          description = errorData?.message;
          button = null;
        } else {
          description = errorData?.message;
        }
        const notificationObject = {
          title: title,
          description: description,
          button: button,
          mode: mode,
          hideClose: false,
        };
        setNotification(notificationObject);
      });
  };

  const onChangeCreateTicket = (e) => {
    setCreateTicket(e);
  };

  const onSubscriptionButtonClick = () => {
    if (!authData.subscription) return openPaymentModal();
    if (authData.subscription?.type !== SUBCRIPTION_TYPE.invoice && authData?.subscription?.status !== 'paid')
      return openPaymentModal();
    if (authData?.subscription?.type === SUBCRIPTION_TYPE.invoice) return openPaymentModal(SUBCRIPTION_TYPE.invoice);
    return openPaymentModal(true);
  };

  // for multiple channel selection event
  const onChannelChange = (item) => {
    setSelectedChannel(item);
    setSelectedLanguage(null);
    getLanguages(item.channelId);
  };

  // language selection event
  const onChangeLanguage = (item) => {
    setSelectedLanguage(item);
    getTemplates(item, selectedChannel?.channelId);
    onFieldValueChange('language', item);
  };

  const displayBuyNowOrChangePlan = () => {
    if (!authData?.subscription) return <>Buy Now</>;
    if (authData?.subscription?.type !== SUBCRIPTION_TYPE.invoice && authData?.subscription?.status !== 'paid')
      return <>Buy Now</>;
    if (authData?.subscription?.type === SUBCRIPTION_TYPE.invoice) return <>Show Plan</>;
    return <>Change Plan</>;
  };

  const isDaysLeftShow = () => {
    if (authData?.auth?.fullAccess) return false;
    if (!authData.subscription) return true;
    if (authData?.subscription?.type !== SUBCRIPTION_TYPE.invoice && authData?.subscription?.status !== 'paid')
      return true;
    return false;
  };

  if (notification || notificationObj?.showNotification)
    return (
      <CenterPageContainer>
        <Notification
          onClose={() => {
            setNotificationObj({});
            setCanSubmit(true);
          }}
          {...notificationObj}
        />
      </CenterPageContainer>
    );

  return (
    <Container location={clientData}>
      <div style={{ display: 'flex' }}>
        <StyleSpan>New Message</StyleSpan>

        <div style={{ fontSize: '12px', width: '50%', textAlign: 'end' }}>
          <Anchor
            isExternal
            href="https://www.youtube.com/playlist?list=PLI5gwarUD_TibmtGsT45mrsrBI4Od8V-D"
            target="_blank"
          >
            Getting Started
          </Anchor>
        </div>
      </div>
      <div>
        {clientData.user?.role === 'admin' && (
          <SettingImg
            alt=""
            src={imgPlaceholder}
            onClick={() =>
              navigate('/settings', {
                replace: true,
                state: { clientData: clientData },
              })
            }
          />
        )}
        {!authData?.auth?.fullAccess && (
          <StyledButton isPrimary onClick={() => onSubscriptionButtonClick()}>
            {displayBuyNowOrChangePlan()}
          </StyledButton>
        )}

        {!authData?.auth?.fullAccess &&
          authData?.subscription?.type === SUBCRIPTION_TYPE.invoice &&
          (authData?.subscription?.status === 'sent' || authData?.subscription?.status === 'open') && (
            <Tag hue="yellow" style={{ float: 'right', marginTop: '4px' }} size="small" isPill>
              <span style={{ fontWeight: '600' }}>Payment Due</span>
            </Tag>
          )}
        {isDaysLeftShow() && (
          <div style={{ display: 'grid', float: 'right', marginTop: '-7px' }}>
            <DaysLeftSpan>Trial</DaysLeftSpan>
            <DaysSpan>{diffDays}</DaysSpan>
            <DaysLeftSpan>Days left</DaysLeftSpan>
          </div>
        )}
      </div>

      {channels?.filter((ele) => ele.isSetup).length > 1 && (
        <Channel onChannelChange={(item) => onChannelChange(item)} selectedChannel={selectedChannel} />
      )}

      <Language onChangeLanguage={(item) => onChangeLanguage(item)} selectedLanguage={selectedLanguage} />
      <Template
        value={payload.template}
        onChange={(field, value) => onFieldValueChange(field, value)}
        selectedLanguage={selectedLanguage}
      />
      <TemplateVars
        template={payload.template}
        onChange={(field, value) => onFieldValueChange(field, value)}
        bulkEnabled={bulkToggle}
        clientData={clientData}
      />
      {payload.template && (
        <MessagePreview
          file={payload.file}
          template={payload.template}
          onChange={(field, value) => onFieldValueChange(field, value)}
        />
      )}
      {clientData?.user?.role === 'admin' && (
        <>
          {(authData?.auth?.fullAccess || authData?.bulkEnabled) && (
            <Field>
              <Toggle onChange={(event) => onChangeBulkToggle(event.target.checked)} defaultChecked={bulkToggle}>
                <PhoneBulkToggleLabel>Bulk Message</PhoneBulkToggleLabel>
              </Toggle>
            </Field>
          )}
        </>
      )}
      {bulkToggle ? (
        <Tags valueTags={payload.tags} onChange={(field, value) => onFieldValueChange(field, value)} />
      ) : (
        <div>
          {clientData?.channel !== 'whatsapp' && authData?.auth?.allowCreateTicket && (
            <Field style={{ marginBottom: '15px' }}>
              <Toggle
                defaultChecked={authData?.auth?.defaultAllowCreateTicketValue}
                onChange={(event) => onChangeCreateTicket(event.target.checked)}
              >
                <PhoneBulkToggleLabel>Create Ticket</PhoneBulkToggleLabel>
              </Toggle>
            </Field>
          )}

          {(!onlyHasOnePhone || !hasTicketId) && (
            <Phone
              value={payload.phone}
              clientData={clientData}
              onChange={(field, value) => onFieldValueChange(field, value)}
            />
          )}
        </div>
      )}
      <SendButtonContainer>
        <SendButton isPrimary disabled={!canSubmit || loading} onClick={() => onSendMessage()}>
          {loading && <Spinner />}

          <Text hidden={loading}>Send</Text>
        </SendButton>
      </SendButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  gap: 23px;
  padding: ${(props) => (props?.location?.location === 'top_bar' ? '20px' : '0px')};
  margin-right: ${(props) => (props?.location?.location !== 'top_bar' ? '10px' : '0px')};
`;

const SendButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyleSpan = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #03363d;
  width: 50%;
`;

const DaysLeftSpan = styled.span`
  font-size: 10px;
  font-weight: 700;
  color: #ff0006;
  text-align: center;
`;

const DaysSpan = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #ff0006;
  text-align: center;
`;

const SettingImg = styled.img`
  position: relative;
  float: right;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  color: #144a75;
  cursor: pointer;
  margin-top: 3px;
`;

const StyledButton = styled(Button)`
  padding: 0 12px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 10px;
  height: 25px;
  float: right;
  margin: 0 5px;
`;

const PhoneBulkToggleLabel = styled(Label)`
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  display: flex;
  height: 23px;
  color: #03363d;
  font-weight: 700;
`;

const Spinner = styled(Inline)`
  position: absolute;
`;

const Text = styled.span`
  visibility: ${(props) => props?.hidden && 'hidden'};
`;

const SendButton = styled(Button)`
  padding: 10px 20px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const CenterPageContainer = styled.div`
  display: grid;
  place-items: center;
  flex: 1;
  margin-top: -23px;
`;

export default SendMessageUi;
