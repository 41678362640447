import React, { useEffect, useState } from 'react';
import { Body, Cell, Head, HeaderCell, HeaderRow, Row, Table } from '@zendeskgarden/react-tables';
import { Checkbox, Field, Label, MediaInput } from '@zendeskgarden/react-forms';
import { onModalClose, openUpgradeModal, shared, zafClient } from '../../api/ZendeskApiService';
import { useBackend } from '../../api/BackendApiService';
import { Spinner } from '@zendeskgarden/react-loaders';
import styled from 'styled-components';
import { ReactComponent as StartIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import Notification from '../../components/notification';
import { Button } from '@zendeskgarden/react-buttons';
import Payment from '../payment';
import InvoiceCheckoutPage from '../payment/InvoiceCheckoutPage';
import { MixPanelActiveAgents, SUBCRIPTION_TYPE } from '../send-message/constants';
import { InfoIcon } from '../../components/icons/info';
import { Mixpanel } from '../../components/MixPanel';
import { Pagination } from '@zendeskgarden/react-pagination';

function Agent({ payment }) {
  const perPage = 100;
  const {
    getAgents,
    agents,
    UpdateAgentAccess,
    notification,
    authData,
    setNotification,
    setAgents,
    userLogin,
    getBillingDetails,
    billingDetails,
  } = useBackend();
  const [data, setData] = useState(agents?.users);
  const [totalCount, setTotalCount] = useState(agents?.count);
  const [proactiveCount, setProactiveCount] = useState(agents?.proactiveCount);
  const [bulkCount, setBulkCount] = useState(agents?.bulkCount);
  const [currentUser, setCurrentUser] = useState(null);
  const [upgradePlan, setUpgradePlan] = useState(false);
  const [invoicePay, setInvoicePay] = useState(false);
  const [page, setPage] = useState(1);
  useEffect(() => {
    setAgents(null);
    getBillingDetails();
    getCurrentUserData();
    if (payment) userLogin();
    shared.resizeFx({
      width: '80vw',
      height: '80vh',
    });
    zafClient.on('modal.close', () => {
      if (!payment) {
        onModalClose().then((ticketSidebarClient) => {
          ticketSidebarClient.trigger('reload');
        });
      }
    });
  }, []);

  useEffect(() => {
    setData(agents?.users);
    setTotalCount(agents?.count);
    setProactiveCount(agents?.proactiveCount);
    setBulkCount(agents?.bulkCount);
  }, [agents, authData]);

  useEffect(() => {
    getAgents(page);
  }, [page]);

  const getCurrentUserData = async () => {
    const { currentUser } = await zafClient.get('currentUser');
    setCurrentUser(currentUser);
  };

  const onCheckboxChange = (e, idx, plan) => {
    const updatedRow = [...data];
    if ((updatedRow[idx].role_type === 1 || updatedRow[idx].only_private_comments) && !updatedRow[idx].proactiveEnabled)
      return;
    let showUpgrade = false;
    if (e.target.checked) {
      if (
        plan === 'Bulk' &&
        updatedRow.filter((ele) => ele.bulkEnabled && ele.role === 'admin').length >=
          authData?.subscription?.totalAdminCount
      ) {
        showUpgrade = true;
        if (
          currentUser?.email === billingDetails?.customerEmail &&
          authData?.subscription?.type !== SUBCRIPTION_TYPE.invoice
        ) {
          zafClient.invoke('destroy');
          openUpgradeModal();
        } else {
          const notificationObject = {
            title: 'Limit Exceed',
            description: 'Agent update limit exceeded. Please upgrade your plan',
            mode: 'error',
            hideClose: false,
          };
          setNotification(notificationObject);
        }
      } else if (
        plan === 'Proactive' &&
        updatedRow.filter((ele) => ele.proactiveEnabled && !ele.bulkEnabled).length >=
          authData?.subscription?.totalUserCount
      ) {
        showUpgrade = true;
        if (currentUser?.id === authData?.auth?.adminId && authData?.subscription?.type !== SUBCRIPTION_TYPE.invoice) {
          zafClient.invoke('destroy');
          openUpgradeModal();
        } else {
          const notificationObject = {
            title: 'Limit Exceed',
            description: 'Agent update limit exceeded. Please upgrade your plan',
            mode: 'error',
            hideClose: false,
          };
          setNotification(notificationObject);
        }
      }
    }

    if (!showUpgrade) {
      let payload = { agentId: updatedRow[idx]?.id.toString() };
      if (updatedRow[idx]?.id === authData?.auth?.adminId) return;
      if (plan === 'Bulk') {
        payload['bulkEnabled'] = e.target.checked;
        payload['proactiveEnabled'] = e.target.checked;
      } else if (plan === 'Proactive') {
        payload['proactiveEnabled'] = e.target.checked;
      }
      UpdateAgentAccess(payload)
        .then((res) => {
          if (plan === 'Bulk') {
            payload.bulkEnabled ? setBulkCount((prevCnt) => prevCnt + 1) : setBulkCount((prevCnt) => prevCnt - 1);
            if (updatedRow[idx].proactiveEnabled !== payload.proactiveEnabled)
              payload.proactiveEnabled
                ? setProactiveCount((prevCnt) => prevCnt + 1)
                : setProactiveCount((prevCnt) => prevCnt - 1);
          }
          if (plan === 'Proactive')
            payload.proactiveEnabled
              ? setProactiveCount((prevCnt) => prevCnt + 1)
              : setProactiveCount((prevCnt) => prevCnt - 1);

          updatedRow[idx].bulkEnabled = !!payload.bulkEnabled;
          updatedRow[idx].proactiveEnabled = !!payload.proactiveEnabled;

          setData(updatedRow);
          Mixpanel.track(MixPanelActiveAgents, {
            Domain: authData?.auth?.domain,
            OneToOne: updatedRow?.filter((val) => val.proactiveEnabled).length,
            Blast: updatedRow?.filter((val) => val.bulkEnabled).length,
          });
        })
        .catch((error) => {
          let description = 'Not able to change agent status';
          let title = 'Agents Update';
          if (error.status !== 500) {
            description = error.responseJSON.message;
          }
          if (error.responseJSON.code === 'TrialMode') {
            title = 'Trial Mode';
            description = 'To give access to this user you need to subscribe to a plan';
          }
          const notificationObject = {
            title: title,
            description: `${description} (${authData?.auth?.domain})`,
            mode: 'error',
            hideClose: false,
          };
          setNotification(notificationObject);
        });
    }
  };

  const onChangeSearch = (e) => {
    if (e.target.value.length > 0) {
      const matchedData = data.filter(
        (item) => item.name.trim().toLowerCase().indexOf(e.target.value.trim().toLowerCase()) !== -1
      );
      setData(matchedData);
    } else setData(agents?.users);
  };

  const licensesCount = () => {
    if (authData?.subscription?.plan?.name.toLowerCase().includes('proactive')) {
      return (
        <span>
          {proactiveCount} out of {authData?.subscription?.totalUserCount} proactive
        </span>
      );
    } else if (authData?.subscription?.plan?.name.toLowerCase().includes('blast')) {
      return (
        <span>
          {bulkCount} out of {authData?.subscription?.totalAdminCount} blast
        </span>
      );
    } else {
      return (
        <span>
          {proactiveCount} out of {authData?.subscription?.totalUserCount} proactive and {bulkCount} out of{' '}
          {authData?.subscription?.totalAdminCount} blast
        </span>
      );
    }
  };

  const disableCheckbox = (planType, rowData) => {
    if ((rowData.role_type === 1 || rowData.only_private_comments) && !rowData.proactiveEnabled) return true;
    if (
      authData?.auth?.fullAccess ||
      !authData?.subscription ||
      (authData?.subscription?.type !== SUBCRIPTION_TYPE.invoice && authData?.subscription?.status !== 'paid')
    )
      return true;
    if (
      !authData?.subscription?.plan?.name?.includes('Combo') &&
      !authData?.subscription?.plan?.name?.includes(planType)
    )
      return true;
    if (rowData.id === authData?.auth?.adminId) return true;
    if (planType === 'Blast' && rowData.role === 'agent') return true;
    if (planType === 'Proactive' && rowData.bulkEnabled) return true;
    return false;
  };

  const onClickChangePlan = () => {
    if (
      !authData?.auth?.fullAccess &&
      authData?.subscription?.type !== SUBCRIPTION_TYPE.invoice &&
      authData?.subscription?.status === 'paid' &&
      currentUser?.id === authData?.auth?.adminId
    )
      return setUpgradePlan(true);
    else if (!authData?.auth?.fullAccess && authData?.subscription?.type === SUBCRIPTION_TYPE.invoice)
      return setInvoicePay(true);
  };

  const showChangePlanButton = () => {
    if (authData?.auth?.fullAccess || !authData?.subscription) return false;
    if (authData?.subscription?.status === 'paid' && currentUser?.id === authData?.auth?.adminId) return true;
    return false;
  };

  const displayButtonText = () => {
    if (authData?.subscription?.type === SUBCRIPTION_TYPE.invoice) return <>Show Plan</>;
    return <>Change Plan</>;
  };

  if (!agents && !notification) {
    return (
      <CenterPageContainer>
        <Spinner size="60" color="#1f73b7" />
      </CenterPageContainer>
    );
  }

  if (notification)
    return (
      <CenterPageContainer>
        <Notification />
      </CenterPageContainer>
    );

  if (upgradePlan) return <Payment upgradePlan={true} agent={true} />;

  if (invoicePay && authData) return <InvoiceCheckoutPage />;

  return (
    <div>
      <StyledSpan>Agent Settings</StyledSpan>
      {showChangePlanButton() && <StyledButton onClick={() => onClickChangePlan()}>{displayButtonText()}</StyledButton>}
      <HorizontalLine></HorizontalLine>
      <DivContainer>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginRight: '10px' }}>
          <StyledSpan>Select which agents/users should have access</StyledSpan>
          <SearchInputField>
            <MediaInput
              size="small"
              start={<StartIcon />}
              isCompact
              placeholder="Search"
              onChange={(e) => onChangeSearch(e)}
            />
          </SearchInputField>
        </div>
      </DivContainer>
      <CountSpan>Licenses : {licensesCount()}</CountSpan>

      <DisclaimerDiv>
        <InfoIcon width={12} height={12} />
        <DisclaimerSpan>
          Only agents with Zendesk Chat enabled will be able to be given access to Chat Inc Proactive. If agent access
          on Zendesk Chat gets disabled you should manually remove access here.
        </DisclaimerSpan>
      </DisclaimerDiv>
      <StyledTable>
        <Head>
          <HeaderRow>
            <ColumnHeader>Agent Name</ColumnHeader>
            <ColumnHeader>Agent Email</ColumnHeader>
            <ColumnHeader>Agent Id</ColumnHeader>
            <ColumnHeader>Agent Role</ColumnHeader>
            <ColumnHeader isMinimum>Blast (Bulk) access</ColumnHeader>
            <ColumnHeader isMinimum>Proactive access</ColumnHeader>
          </HeaderRow>
        </Head>
        <Body>
          {data?.map((row, idx) => (
            <Row key={idx}>
              <NameCell>{row.name}</NameCell>
              <RemainingCell>{row.email}</RemainingCell>
              <RemainingCell>{row.id}</RemainingCell>
              <RemainingCell>{row.role}</RemainingCell>
              <RemainingCell isMinimum>
                <Field>
                  <Checkbox
                    checked={row.bulkEnabled}
                    onChange={(e) => onCheckboxChange(e, idx, 'Bulk')}
                    disabled={disableCheckbox('Blast', row)}
                  >
                    <CheckboxLabel hidden>Select row for {row.agent_name}</CheckboxLabel>
                  </Checkbox>
                </Field>
              </RemainingCell>
              <RemainingCell isMinimum>
                <Field>
                  <Checkbox
                    checked={row.proactiveEnabled}
                    onChange={(e) => onCheckboxChange(e, idx, 'Proactive')}
                    disabled={disableCheckbox('Proactive', row)}
                  >
                    <CheckboxLabel hidden>Select row for {row.agent_name}</CheckboxLabel>
                  </Checkbox>
                </Field>
              </RemainingCell>
            </Row>
          ))}
        </Body>
      </StyledTable>
      {totalCount > perPage && (
        <PaginationContainer>
          <nav aria-label={`page ${page}`}>
            <Pagination
              totalPages={Math.ceil(totalCount / perPage)}
              pageGap={4}
              currentPage={page}
              onChange={setPage}
            />
          </nav>
        </PaginationContainer>
      )}
    </div>
  );
}

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
`;

const CenterPageContainer = styled.div`
  display: grid;
  place-items: center;
  flex: 1;
  margin-top: -23px;
`;

const StyledSpan = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: #03363d;
  margin-left: 13px;
`;

const HorizontalLine = styled.hr`
  margin-top: 26px;
  height: 2px;
  color: #e9ebed;
`;

const ColumnHeader = styled(HeaderCell)`
  font-size: 14px;
  font-weight: 600;
  width: 10vw;
  line-heigth: 20px;
`;

const NameCell = styled(Cell)`
  font-size: 12px;
  font-weight: 600;
  color: #1f73b7;
  line-height: 20px;
`;

const RemainingCell = styled(Cell)`
  font-size: 12px;
  font-weight: 400;
  color: #03363d;
  line-height: 20px;
`;

const CheckboxLabel = styled(Label)`
  cursor: ${(props) => props?.disabled && 'not-allowed'};
`;

const CountSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #03363d;
  line-heigth: 20px;
  margin-left: 13px;
`;

const DisclaimerSpan = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #03363d;
  line-heigth: 20px;
  margin-left: 5px;
`;

const DisclaimerDiv = styled.div`
  margin-top: 15px;
  background: #fafafa;
  padding: 9px 10px;
`;

const SearchInputField = styled(Field)`
  width: 260px;
  heigth: 32px
  padding: 6px 12px;
  margin-left: 13px
`;

const DivContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-top: 17px;
  justify-content: space-between;
`;

const StyledTable = styled(Table)`
  border: 0.25px solid #e9ebed;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  font-weight: 700;
  font-size: 12px;
  height: 36px;
  float: right;
  margin-right: 15px;
`;

export default Agent;
