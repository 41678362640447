import { Dropdown, Field, Item, Label, Menu, Multiselect } from '@zendeskgarden/react-dropdowns';
import { Tag } from '@zendeskgarden/react-tags';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useBackend } from '../../../api/BackendApiService';
import { InfoTooltip } from '../../../components/tooltip';
import useDebounce from '../../../components/useDebounce';

function Tags(props) {
  const { onChange, valueTags } = props;
  const [selectedTagsValue, setSelectedTagsValue] = useState([]);
  const [tagList, setTagList] = useState([]);
  const { tags, getTags } = useBackend();

  useEffect(() => {
    setTagList(tags);
  }, [tags]);

  useEffect(() => {
    setSelectedTagsValue(valueTags);
  }, [valueTags]);

  const onChangeTagsValue = (item) => {
    setSelectedTagsValue(item);
    onChange('tags', item);
  };

  // Use the correct useDebounce import
  const debounceSearch = useDebounce((value) => getTags(value), 600);

  return (
    <Dropdown
      selectedItems={selectedTagsValue}
      onSelect={(item) => onChangeTagsValue(item)}
      downshiftProps={{ defaultHighlightedIndex: 0 }}
      onInputValueChange={(value) => debounceSearch(value)}
    >
      <Field>
        <SelectLabelContainer>
          <SelectLabel>Tags:</SelectLabel>
          <InfoTooltip tooltip="Select the tag/s that you want to send a message to" />
        </SelectLabelContainer>
        <Multiselect
          disabled={false}
          renderItem={({ value, removeValue }) => {
            return (
              <Tag>
                <span>{value}</span>
                <Tag.Close onClick={() => removeValue()} />
              </Tag>
            );
          }}
        />
      </Field>

      <Menu>
        {tagList && tagList.length === 0 ? (
          <Item disabled>No Tags Found</Item>
        ) : (
          tagList &&
          tagList.map((option) => (
            <Item key={option.name} value={option.name}>
              {option.name}
            </Item>
          ))
        )}
      </Menu>
    </Dropdown>
  );
}

const SelectLabel = styled(Label)`
  color: #03363d;
  font-size: 12px;
  font-weight: 700;
`;

const SelectLabelContainer = styled(Label)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;

  //because Select.Value already have margin-top: 4px;
  // margin-bottom: 7px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

export default Tags;
