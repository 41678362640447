import styled from 'styled-components';
import { Field as InputField, Input, Label as InputLabel } from '@zendeskgarden/react-forms';
import { InfoTooltip } from '../../../components/tooltip';
import { Button } from '@zendeskgarden/react-buttons';
import { Spinner } from '@zendeskgarden/react-loaders';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useBackend } from '../../../api/BackendApiService';

const Sunco = ({ selectedChannel, onSuccess }) => {
  const { loading, register } = useBackend();
  const [suncoNamespace, setSuncoNamespace] = useState('');
  const {
    state: { clientData },
  } = useLocation();
  const naviagte = useNavigate();
  const lastPage =
    clientData.location === 'user_sidebar'
      ? '/zd-user'
      : clientData.location === 'ticket_sidebar'
      ? '/zd-tickets'
      : '/zd-modal';

  const onSubmitChannelSetup = async () => {
    let payload = {
      name: selectedChannel?.displayName,
      namespace: suncoNamespace,
      zendeskChannelId: selectedChannel?._id,
    };
    const res = await register(payload);
    if (res) {
      onSuccess();
      return;
    }
  };

  return (
    <div>
      <SuncoSettingControl
        key={'suncoNamespace'}
        name={'suncoNamespace'}
        label="Sunshine Conversations Namespace"
        value={suncoNamespace}
        onInputChange={(value) => setSuncoNamespace(value)}
        tooltip={
          <p>
            If you are not sure what the namespace is please reach out to Zendesk -{' '}
            <a href="mailto:conversations-support@zendesk.com" style={{ color: 'white' }}>
              conversations-support@zendesk.com
            </a>{' '}
            or email our support team at{' '}
            <a href="mailto:zendesk@chatinc.com" style={{ color: 'white' }}>
              zendesk@chatinc.com
            </a>{' '}
            and we will do our best to assist you in getting this information
          </p>
        }
        marginBottom="20px"
      />

      <ButtonsContainer>
        <StyledButton onClick={() => naviagte(lastPage, { replace: true })}>Back</StyledButton>

        <Container>
          <SetupButton
            isPrimary
            disabled={suncoNamespace?.length === 0 || loading}
            onClick={() => onSubmitChannelSetup()}
          >
            {loading && <Spinner />}
            <Text hidden={loading}>Submit</Text>
          </SetupButton>
        </Container>
      </ButtonsContainer>
    </div>
  );
};

export default Sunco;

const SuncoSettingControl = (props) => {
  const { label, tooltip, value, name, onInputChange, marginBottom } = props;
  return (
    <InputField style={{ marginBottom: marginBottom, textAlign: 'left' }}>
      <InputLabelContainer>
        <span style={{ color: '#03363d' }}>{label}</span>
        {tooltip && <InfoTooltip setUp={true} tooltip={tooltip} placement="end" />}
      </InputLabelContainer>
      {name === 'suncoNamespace' && (
        <ExtraSpan style={{ fontSize: '10px' }}>
          Identifier provided by Sunshine Conversations. You need this in order to send notifications using templates.
        </ExtraSpan>
      )}
      <Input
        isCompact
        value={value}
        onChange={(event) => onInputChange(event.target.value, name)}
        style={{ marginTop: '7px' }}
      />
    </InputField>
  );
};

const InputLabelContainer = styled(InputLabel)`
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: max-content 16px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

const Container = styled.div`
  display: grid;
  gap: 23px;
  padding: ${(props) => (props.location === 'top_bar' ? '20px !important' : '0px')};
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  margin-right: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
`;

const SetupButton = styled(Button)`
  padding: 10px 20px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const Text = styled.span`
  visibility: ${(props) => props.hidden && 'hidden'};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const ExtraSpan = styled.span`
  font-size: 11px;
  color: #68737d;
  font-weight: 400;
  line-height: 15px;
  margin: 3px 0;
  text-align: left;
`;
