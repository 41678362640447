import { Dropdown, Field, Item, Label, Menu, Select } from '@zendeskgarden/react-dropdowns';
import styled from 'styled-components';
import { useBackend } from '../../../api/BackendApiService';
import { InfoTooltip } from '../../../components/tooltip';
import { Checkbox, Field as InputField, Label as InputLabel } from '@zendeskgarden/react-forms';
import { Button } from '@zendeskgarden/react-buttons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from '@zendeskgarden/react-loaders';
import { useEffect, useState } from 'react';
import { TIER_LIMIT } from '../constants';
import { tierLimitUpgradeModal, zafClient } from '../../../api/ZendeskApiService';

import React from 'react';

function ChannelSettings({ selectedChannel }) {
  const { languages, loading, save, channels, authData, getLanguages } = useBackend();
  const [isDefault, setIsDefault] = useState(channels?.find((ch) => ch.isDefault).isDefault);
  const [selectedLanguage, setSelectedLanguage] = useState(authData?.channel?.templateDefaultLanguage);
  const [selectedTierLimit, setSelectedTierLimit] = useState(authData?.channel?.dailyLimit);
  const {
    state: { clientData },
  } = useLocation();
  const naviagte = useNavigate();
  const lastPage =
    clientData.location === 'user_sidebar'
      ? '/zd-user'
      : clientData.location === 'ticket_sidebar'
      ? '/zd-tickets'
      : '/zd-modal';

  useEffect(() => {
    getLanguages(selectedChannel?.channelId);
    setSelectedLanguage(selectedChannel?.templateDefaultLanguage);
  }, [selectedChannel]);

  useEffect(() => {
    zafClient.on('confirm', (selected) => {
      localStorage.removeItem('lastVisitedPage');
      setSelectedTierLimit(parseInt(selected));
      onSubmitSetting(parseInt(selected));
    });

    zafClient.on('cancel', () => {
      localStorage.removeItem('lastVisitedPage');
      setSelectedTierLimit(authData?.channel?.dailyLimit);
    });
  }, []);

  const onSubmitSetting = (tierLimit = null) => {
    let data = {
      id: selectedChannel?._id,
      templateDefaultLanguage: selectedLanguage,
      isDefault: isDefault,
    };
    if (clientData?.user?.id === authData?.auth?.adminId) data.dailyLimit = tierLimit ? tierLimit : selectedTierLimit;
    save(data);
  };

  const onChangeTierLimit = (item) => {
    if (selectedTierLimit && parseInt(item) > parseInt(selectedTierLimit)) {
      const params = {
        currentTier: selectedTierLimit,
        selectedTier: item,
      };
      setSelectedTierLimit(item);
      tierLimitUpgradeModal(params);
    } else {
      setSelectedTierLimit(item);
    }
  };

  return (
    <>
      <InputField aria-disabled={true}>
        <Checkbox checked={isDefault} onChange={() => setIsDefault(!isDefault)}>
          <InputLabel tooltip="Will this be the default channel that you will send messages from?">
            <span style={{ lineHeight: '20px', fontWeight: '400', color: '#03363D', fontSize: '12px' }}>
              Is This a Default Channel?
            </span>
          </InputLabel>
        </Checkbox>
      </InputField>
      <Dropdown
        selectedItem={selectedLanguage}
        onSelect={(item) => setSelectedLanguage(item)}
        downshiftProps={{ itemToString: (item) => item && item }}
      >
        <Field>
          <SelectLabelContainer>
            <SelectLabel>Select Default Language</SelectLabel>
            <InfoTooltip tooltip="Choose the language submitted for the pre-approved template message you want to use" />
          </SelectLabelContainer>

          <Select disabled={false} isCompact>
            {selectedLanguage?.toUpperCase()}
          </Select>
        </Field>

        <Menu>
          {languages && languages.length > 0 ? (
            languages.map((option) => (
              <Item key={option} value={option}>
                {option.toUpperCase()}
              </Item>
            ))
          ) : (
            <Item disabled>No language found</Item>
          )}
        </Menu>
      </Dropdown>
      {clientData?.user?.id === authData?.auth?.adminId && (
        <Dropdown
          selectedItem={selectedTierLimit}
          onSelect={(item) => onChangeTierLimit(item)}
          downshiftProps={{ itemToString: (item) => item && item }}
        >
          <Field>
            <SelectLabelContainer>
              <SelectLabel>WhatsApp Tier Limit</SelectLabel>
            </SelectLabelContainer>
            <ExtraSpan style={{ fontSize: '10px' }}>
              Select the Send Tier according to the Meta Message Tier for this channel. Contact
              <a href="mailto:engineering-support@smooch.io"> engineering-support@smooch.io</a> to confirm your Tier
              Limit and read more about messaging limits here:{' '}
              <a href="https://developers.facebook.com/docs/whatsapp/messaging-limits/" target="_blank">
                https://developers.facebook.com/docs/whatsapp/messaging-limits/
              </a>{' '}
            </ExtraSpan>
            <Select disabled={false} isCompact style={{ marginTop: '5px' }}>
              {selectedTierLimit && (
                <>
                  {`Tier ${
                    TIER_LIMIT.findIndex((item) => item === selectedTierLimit) + 1
                  } (${selectedTierLimit} contacts)`}
                </>
              )}
            </Select>
          </Field>

          <Menu>
            {TIER_LIMIT ? (
              TIER_LIMIT.map((option, idx) => (
                <Item key={option} value={option}>
                  {`Tier ${idx + 1} (${option} contacts)`}
                </Item>
              ))
            ) : (
              <Item disabled>Loading...</Item>
            )}
          </Menu>
        </Dropdown>
      )}
      {!selectedTierLimit && (
        <StyleSpan>
          Disclaimer message: Increasing your tier limit may have cost implications. Please ensure that the tier limit
          is correct as Meta will validate your tier.
        </StyleSpan>
      )}

      <ButtonsContainer>
        <StyledButton onClick={() => naviagte(lastPage, { replace: true })}>Back</StyledButton>
        <Container>
          <SetupButton isPrimary disabled={!selectedChannel || loading} onClick={() => onSubmitSetting()}>
            <>{loading ? <Spinner /> : <Text>Save</Text>}</>
          </SetupButton>
        </Container>
      </ButtonsContainer>
    </>
  );
}

const StyleSpan = styled.span`
  line-height: 20px;
  font-weight: 400;
  color: #03363d;
  font-size: 13px;
`;

const Container = styled.div`
  display: grid;
  gap: 23px;
  padding: ${(props) => (props.location === 'top_bar' ? '20px !important' : '0px')};
`;

const SelectLabelContainer = styled(Label)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;

  //because Select.Value already have margin-top: 4px;
  // margin-bottom: 7px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

const SelectLabel = styled(Label)`
  color: #03363d;
  font-size: 12px;
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  padding: 10px 20px;
  margin-right: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
`;

const SetupButton = styled(Button)`
  padding: 10px 20px;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

const Text = styled.span`
  visibility: ${(props) => props.hidden && 'hidden'};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const ExtraSpan = styled.span`
  font-size: 11px;
  color: #03363d;
  font-weight: 400;
  line-height: 15px;
`;

export default ChannelSettings;
