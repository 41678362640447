import { Field as InputField, Input, Label as InLabel, Message } from '@zendeskgarden/react-forms';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InfoTooltip } from '../../../components/tooltip';
import { Autocomplete, Dropdown, Field, Item, Label, Menu } from '@zendeskgarden/react-dropdowns';
import { Button } from '@zendeskgarden/react-buttons';
import { auto } from '@popperjs/core';
import { useBackend } from '../../../api/BackendApiService';

function TemplateVars(props) {
  const { varFields } = useBackend();
  const { template, onChange, bulkEnabled, clientData } = props;
  const [items, setItems] = useState(null);
  const [addedItem, setAddedItem] = useState('');
  const [varVal, setVarVal] = useState({});
  const [searchedItem, setSearchedItem] = useState('');

  useEffect(() => {
    setVarVal({});
    onChange('vars', null);
    setItems(varFields);
    setAddedItem('');
    setSearchedItem('');
    setInitalVarValue();
    if (varFields && !bulkEnabled && clientData?.location === 'ticket_sidebar') {
      varFields.push({ label: 'Ticket No', field: 'ticketId' });
      setItems(varFields);
    } else if (items?.find((ele) => ele.field === 'ticketId')) {
      const options = [...items];
      options.splice(
        options.findIndex((ele) => ele.field === 'ticketId'),
        1
      );
      varFields?.splice(
        varFields.findIndex((ele) => ele.field === 'ticketId'),
        1
      );
      setItems(options);
      onChange('vars', null);
    }
  }, [template, varFields, bulkEnabled]);

  const setInitalVarValue = () => {
    const templateVar = varVal;
    if (template && template.vars) {
      template.vars.map((name) => {
        if (name.isUrl) {
          templateVar[name.name] = name.value.length > 0 ? name.value : name.urlPrefix;
        } else {
          if (name.value?.includes('<')) {
            name.value = name.value.replace('<', '').replace('>', '');
            if (items) {
              const options = [...items];
              options.push({ label: name.value, field: name.value });
              setItems(options);
            }
          }
          templateVar[name.name] = name.value;
        }
      });
    }
    setVarVal(templateVar);
    onChange(templateVar);
  };

  const onChangeVarValue = (value, idx) => {
    const templateVar = varVal;
    const name = value.name.name;
    const val = value.value;
    if (value.name.isUrl) {
      if (val.length <= value.name.urlPrefix.length) {
        templateVar[name] = value.name.urlPrefix;
        template.vars[idx].value = '';
      } else {
        templateVar[name] = val;
        template.vars[idx].value = val;
      }
    } else {
      if (varFields?.find((ele) => ele.field === val)) {
        templateVar[name] = val;
        template.vars[idx].value = val;
      } else {
        templateVar[name] = val;
        template.vars[idx].value = `<${val}>`;
      }
    }
    setVarVal(templateVar);
    onChange('vars', template?.vars);
  };

  const addItem = (e) => {
    const options = [...items];
    options.push({ label: addedItem, field: addedItem });
    setItems(options);
    setAddedItem('');
  };

  const onNameChange = (event) => {
    setAddedItem(event.target.value);
  };

  const onSearchVars = (value) => {
    if (value.length > 0) {
      const matchedOptions = items?.filter((option) => {
        return option.label.trim().toLowerCase().includes(value.trim().toLowerCase());
      });
      if (matchedOptions.length === 0) setAddedItem(value);
      setSearchedItem(value);
    } else {
      setAddedItem('');
      setSearchedItem('');
    }
  };

  return (
    <>
      {!!template &&
        template?.vars?.map((name, idx) => (
          <div key={idx}>
            {name.isUrl ? (
              <>
                <InputField key={name.name}>
                  <InputLabelContainer>
                    <InputLabel>Enter URL Variable {name.name}</InputLabel>
                    <InfoTooltip tooltip="Type at the end of the link to complete the URL" />
                  </InputLabelContainer>

                  <Input
                    isCompact
                    defaultValue={name.isUrl ? name.urlPrefix : ''}
                    disabled={false}
                    placeholder={name.placeholder}
                    value={varVal[name.name]}
                    onChange={(event) => onChangeVarValue({ value: event.target.value, name }, idx)}
                  />
                  <Message validation={name.value.length > name.urlPrefix.length ? 'success' : 'error'}>
                    {name.value.length > name.urlPrefix.length ? <>Success</> : <>Enter url path</>}
                  </Message>
                </InputField>
              </>
            ) : (
              <div
                key={idx}
                onBlur={() => {
                  if (addedItem.length > 0) {
                    addItem();
                    onChangeVarValue({ value: addedItem, name }, idx);
                  }
                  setAddedItem('');
                  setSearchedItem('');
                }}
                style={{ maxWidth: '92vw' }}
              >
                <Dropdown
                  selectedItem={varVal[name.name]}
                  onSelect={(item) => onChangeVarValue({ value: item, name }, idx)}
                  downshiftProps={{ itemToString: (item) => item && item }}
                  onInputValueChange={(value) => onSearchVars(value)}
                >
                  <Field>
                    <SelectLabelContainer>
                      <SelectLabel>Variable {idx + 1}</SelectLabel>
                      <InfoTooltip tooltip="Select the data you want to use for the variable in this template" />
                    </SelectLabelContainer>

                    <Autocomplete disabled={false} isCompact>
                      {items?.find((item) => item.field === varVal[name.name])?.label}
                    </Autocomplete>
                  </Field>

                  <Menu>
                    <div style={{ overflowY: 'hidden', height: '300px' }}>
                      <div style={{ overflowY: auto, height: '85%' }}>
                        {items
                          ?.filter((ele) => ele.label.trim().toLowerCase().includes(searchedItem?.trim().toLowerCase()))
                          ?.map((item, idx) => {
                            return (
                              <Item key={idx} value={item.field}>
                                {item.label}
                              </Item>
                            );
                          })}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px', padding: '0 7px' }}>
                        <Input
                          placeholder="Please enter item"
                          style={{ width: '75%', marginLeft: '5px' }}
                          value={addedItem}
                          onChange={(e) => onNameChange(e)}
                          isCompact
                        />
                        <StyledButton onClick={() => addItem()} disabled={addedItem.length === 0} style={{}}>
                          Add item
                        </StyledButton>
                      </div>
                    </div>
                  </Menu>
                </Dropdown>
              </div>
            )}
          </div>
        ))}
    </>
  );
}

const InputLabel = styled(InLabel)`
  color: #03363d;
  font-size: 12px;
  font-weight: 700;
`;

const InputLabelContainer = styled(InLabel)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

const SelectLabelContainer = styled(Label)`
  display: grid;
  align-items: center;
  grid-template-columns: max-content 16px;
  gap: 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
`;

const SelectLabel = styled(Label)`
  color: #03363d;
  font-size: 12px;
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
  height: 30px;
`;

export default TemplateVars;
